<template>
    <v-dialog v-model="dial_cliente" max-width="750" transition="dialog-top-transition" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-switch style="font-size:70%;" dense v-model="es_menor" color="green" label="MENOR DE EDAD"></v-switch>
                <v-spacer></v-spacer>

            </v-system-bar>
        </div>
        {{ calcula_fecha }}
        <v-card class="mx-auto pa-3" color="white">
            <v-row class="mt-n4">
                <v-col cols="6">
                    <v-text-field @focus="$event.target.select()" :disabled="activa_doc" outlined autofocus type="number"
                        dense v-model="documento" label="N° Doc" append-icon="mdi-magnify" @click:append="buscar_bd(1)"
                        @keyup.enter="buscar_bd(1)"></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-text-field @blur="siguiente('id_edad')" outlined type="number" dense v-model="telefono"
                        label="Telefono"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-n9">
                <v-col cols="12">
                    <v-textarea outlined dense v-model="nombre" auto-grow filled label="Nombre" rows="1"></v-textarea>
                </v-col>
            </v-row>
            <v-row class="mt-n9" v-if="false">
                <v-col cols="6">
                    <v-text-field outlined dense v-model="departamento" label="DEPARTAMENTO"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="provincia" label="PROVINCIA"></v-text-field>
                </v-col>
            </v-row>

            <v-row class="mt-n9" v-if="false">
                <v-col cols="6">
                    <v-text-field outlined dense v-model="distrito" label="DISTRITO"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="ubigeo" label="UBIGEO"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-n7" dense>
                <v-col cols="12">
                    <v-textarea outlined dense v-model="direccion" auto-grow filled label="Direccion" rows="1"></v-textarea>
                </v-col>
            </v-row>
            <v-row class="mt-n5" dense>
                <v-col cols="12">
                    <v-text-field type="email" outlined dense v-model="correo" label="CORREO"></v-text-field>
                </v-col>
            </v-row>

            <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n4' : 'mt-n5 text-center'">
                <v-col cols="12" md="6" xs="12">
                    <v-row dense>
                        <v-col cols="1">
                            <v-checkbox v-model="activa_fecha" label="" dense></v-checkbox>
                        </v-col>
                        <v-col cols="11">
                            <v-text-field :disabled="!activa_fecha" type="date" class="redondeado" outlined dense
                                v-model="date1" label="Nacimento" @click="foco_edad = false"></v-text-field>
                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="12" md="6" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-n4' : 'text-center'">
                    <v-text-field :disabled="activa_fecha" id="id_edad" type="number" outlined dense v-model="edad"
                        label="Edad" @click="foco_edad = true"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-n6">
                <v-col cols="6">
                    <v-select :items="$store.state.tiendas" label="Tienda" dense outlined v-model="tienda"></v-select>
                </v-col>
                <v-col cols="4">
                </v-col>
                <v-col cols="2">

                    <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
                </v-col>
            </v-row>
        </v-card>
        <clientes :dial_L="dial_client" @array="agregacliente($event)" @cierra="dial_client = false" />
    </v-dialog>
</template>

<script>
import axios from "axios"
import store from '@/store/index'
import moment from 'moment'
import clientes from '@/components/dialogos/dialogoClientes'
import {
    nuevoCliente,
    buscaCliente,
} from '../../db'
import {
    obten_contador_externo,
    sumarCorrelativo_externo
} from '../../contadores'
export default {
    props: {
        dial_cliente: '',
        num_cliente: ''
    },
    name: 'caja',
    components: {
        clientes
    },
    data() {
        return {
            date1: moment(String(new Date)).format('YYYY-MM-DD'),
            arraydocumento: ['DNI', 'RUC', 'CE'],
            departamento: 'LA LIBERTAD',
            provincia: 'TRUJILLO',
            distrito: 'TRUJILLO',
            direccion: '',
            ubigeo: '130101',
            nombre: '',
            tipodoc: 'RUC',
            documento: '',
            telefono: '',
            edad: '',
            correo: '',
            tienda: 'MERCADERES',
            foco_edad: false,
            es_menor: false,
            activa_doc: false,
            contador: '',
            dial_client: false,
            suma_contador: false,
            activa_fecha: false,
        }
    },
    created() {

        if (Boolean(this.num_cliente)) {
            this.documento = this.num_cliente
            this.buscar_bd(1)
        }
        this.carga()
    },

    computed: {

        calcula_fecha() {
            if (this.edad != 0) {
                if (this.edad < 18) {
                    this.es_menor = true
                } else {
                    this.es_menor = false
                }
            }
            if (this.es_menor) {
                //this.documento = this.contador
                this.activa_doc = true
            } else {
                this.activa_doc = false
            }
            if (this.date1 == '1900-01-01') {
                console.log("desactivar")
            }
            if (this.activa_fecha) {
                var nacimiento = moment(this.date1);
                var hoy = moment();
                var anios = hoy.diff(nacimiento, "years");
                if (anios != 0) {
                    this.edad = anios
                }
            }

        }
    },
    methods: {
        async carga() {
            this.contador = await obten_contador_externo("orden_pacientes")
        },
        async buscar_bd(val) {
            if (this.documento.trim() == '' && val != 0) {
                this.dial_client = true
            } else {

                if (this.documento != '') {

                    var snapshot = await buscaCliente(this.documento.trim())
                        .once("value")
                    if (snapshot.exists()) {
                        var data = snapshot.val()
                        this.suma_contador = false
                        this.es_menor = data.es_menor
                        this.documento = data.id
                        this.tipodoc = data.tipodoc
                        this.documento = data.documento
                        this.nombre = data.nombre
                        this.correo = data.correo
                        this.departamento = data.departamento
                        this.provincia = data.provincia
                        this.distrito = data.distrito
                        this.ubigeo = data.ubigeo
                        this.direccion = data.direccion
                        this.telefono = data.telefono
                        this.edad = data.edad
                        this.tienda = data.tienda
                        this.activa_fecha = data.activa_fecha
                        this.date1 = moment.unix(data.nacimiento).format('YYYY-MM-DD')
                    } else {

                        this.BuscarDocumento()
                    }
                    return
                }

            }
        },
        BuscarDocumento() {
            if (this.documento != '' &&
                this.documento.length == 8 || this.documento.length == 11) {
                if (Number(this.documento.length) == 8) {
                    this.tipodoc = "DNI"
                }
                if (Number(this.documento.length) == 11) {
                    this.tipodoc = "RUC"
                }
                console.log("asd")
                store.commit("dialogoprogress", 1)
                var self = this,
                    token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
                axios
                    .get('https://apiperu.dev/api/' + this.tipodoc.toLowerCase() + '/' + this.documento, {
                        headers: {
                            Content_Type: 'application/json',
                            authorization: ' Bearer ' + token
                        }
                    })
                    .then(response => (this.info = response.data,
                        store.commit("dialogoprogress", 1),
                        self.llenardatos(response.data.data)
                    ))
                    .catch(error => {
                        store.commit("dialogoprogress", 1),
                            self.snack('Documento Invalido')
                    })
            } else {
                this.snack("Ingrese Documento")
            }
        },
        llenardatos(data) {
            this.nombre = data.nombre_o_razon_social
            if (this.tipodoc == 'DNI') {
                this.nombre = data.nombre_completo
            }
            if (Boolean(data.direccion)) {
                this.direccion = data.direccion
            }
            if (Boolean(data.departamento)) {
                this.departamento = data.departamento
            }
            if (Boolean(data.provincia)) {
                this.provincia = data.provincia
            }
            if (Boolean(data.distrito)) {
                this.distrito = data.distrito
            }
            if (Boolean(data.ubigeo_sunat)) {
                this.ubigeo = data.ubigeo_sunat
            }
            this.suma_contador = false
        },
        async save() {
            if (this.nombre == '') {
                alert('Complete nombre')
                return
            }
            if (this.documento == '') {
                this.suma_contador = true
                this.documento = this.contador
            }
            var fecha = moment(String(this.date1)) / 1000
            var array = {
                activo: true,
                id: this.documento,
                tipodoc: this.tipodoc,
                documento: this.documento,
                nombre: this.nombre.toUpperCase(),
                correo: this.correo,
                departamento: this.departamento,
                provincia: this.provincia,
                distrito: this.distrito,
                ubigeo: this.ubigeo,
                direccion: this.direccion,
                telefono: this.telefono,
                activa_fecha: this.activa_fecha,
                nacimiento: fecha,
                edad: this.edad,
                tienda: this.tienda,
                es_menor: this.es_menor
            }
            if (this.suma_contador) {
                await sumarCorrelativo_externo("orden_pacientes", array.id)
                this.suma_contador = false
            }
            console.log(array)
            nuevoCliente(array.id, array).then(() => {
                this.$emit('cliente', array)
            })
        },
        agregacliente(data) {
            this.suma_contador = false
            this.dial_client = false
            this.documento = data.id
            this.buscar_bd(1)

        },
        cierra() {
            this.$emit('cierra', false)
        },
        siguiente(next) {
            document.getElementById(next).focus();
        }
    },

}
</script>
