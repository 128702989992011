import axios from "axios"
import store from '@/store/index'
export const send_sms = async (mensaje, numero) => {

    var a = axios({
        method: 'POST',
        url: 'https://us-central1-whatsapp-peru.cloudfunctions.net/api',
        //url: 'http://localhost:5000/whatsapp-peru/us-central1/api',
        headers: {
            'authorization': 'Bearer ' + store.state.configuracion.token_sms,
            'Content-Type': 'application/json'
        },
        data: {
            "emisor": "",
            "sms": [{
                "numero": numero,
                "mensaje": mensaje,
                "url": ""
            }],
            "metodo": "send_sms"
        }

    }).then(response => {
        console.log(response.data)
        return response
    })
    return a
}