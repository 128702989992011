<template>
    <v-dialog v-model="dial_activo" max-width="900" transition="dialog-top-transition" persistent>

        <div>
            <v-system-bar window dark height="40">
                <v-icon large color="red" @click="termina()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-btn v-if="ultima_med != ''" color="info" class="" small @click="copia_histo()">
                    <v-icon dark left>
                        mdi-content-copy
                    </v-icon> Medicion {{ conviertefecha(data_historial.fecha) }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-icon large color="red" @click="cambiar()">mdi-key-change</v-icon>
                <v-spacer></v-spacer>
                <h4>{{ obten_serie() }}</h4>
            </v-system-bar>
        </div>

        <v-card class="pa-8" style="overflow-y: scroll;overflow-x: hidden; max-height:70vh">

            {{ suma_DNP }}
            <v-row class="mt-n9 mb-2 m-n6" dense>
                <v-col cols="12" class="">
                    <v-card class="pa-2">
                        <v-row class="mt-1 mb-n2" dense>
                            <v-col cols="2" class="mt-n3">
                                <v-img src="doctor.png" alt="Sunny image" width="40"></v-img>
                            </v-col>
                            <v-col cols="10" class="ml-n5">
                                <p style="font-size:80%;font-style:oblique;">Paciente: <strong>{{ cliente.nombre
                                        }} - Edad : {{ cliente.edad }}</strong>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>

                </v-col>
                <v-col cols="6" class="">
                    <v-card class="pa-2">
                        <v-row class="mt-n1 mb-n4" dense>
                            <v-col cols="12" class="">
                                <p style="font-size:80%;font-style:oblique;">Diagnostico: <span>{{ diagnostico()
                                        }}</span>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>

                </v-col>
                <v-col cols="6" class="">
                    <v-card class="pa-2">
                        <v-row class="mt-n1 mb-n4" dense>

                            <v-col cols="8" class="">

                                <p style="font-size:80%;font-style:oblique;">Tienda: <span>{{ tienda
                                        }}</span>
                                </p>
                            </v-col>
                            <v-col cols="4" class="">
                                <v-icon class="ml-16 mt-n2" large color="red"
                                    @click="dialog_tiendas = true">mdi-swap-horizontal</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>

                </v-col>
            </v-row>
            <v-expansion-panels v-model="panel" :disabled="disabled">
                <v-expansion-panel>
                    <v-expansion-panel-header class="mt-n2">
                        <v-row>
                            <v-col cols="6" class="pa-2" style="font-size:80%;">
                                <v-icon class="mt-n1" color="red" dark small>mdi-eye-outline</v-icon> O.D.
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-row class="mt-n4 pa-1 text-center red--text" dense style="font-size:80%;">
                        <v-col cols="2">
                            Esfe.
                        </v-col>
                        <v-col cols="2">
                            Cili.
                        </v-col>
                        <v-col cols="8">
                            <v-row class="mt-n2 pa-1 text-center red--text" dense>
                                <v-col cols="3">
                                    Eje
                                </v-col>
                                <v-col cols="3">
                                    DNP
                                </v-col>
                                <v-col cols="3">
                                    A/V
                                </v-col>
                                <v-col cols="3">
                                    PIO
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n4 pa-1 text-center" dense style="font-size:80%;">
                        <v-col cols="2">
                            {{ devuelve_simbolo(esfera_od) }}
                        </v-col>
                        <v-col cols="2">
                            {{ devuelve_simbolo(cilindro_od) }}
                        </v-col>
                        <v-col cols="8">
                            <v-row class="mt-n2 pa-1 text-center" dense>

                                <v-col cols="3">
                                    {{ eje_od }}°
                                </v-col>
                                <v-col cols="3">
                                    {{ dnp_od }}mm
                                </v-col>
                                <v-col cols="3">
                                    20/{{ av_od }}
                                </v-col>
                                <v-col cols="3">
                                    {{ pio_od }}mmHg
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-expansion-panel-content class="mt-n1">
                        <v-subheader class="ml-n7 mb-n3" style="font-size:80%;">Esfera</v-subheader>
                        <v-slider v-model="esfera_od" step="0.25" :max="max" :min="min" dense thumb-label
                            :thumb-size="tamano_letra">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement('esfera')">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment('esfera')">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-subheader class="ml-n7 mb-n3 mt-n8" style="font-size:80%;">Cilindro</v-subheader>
                        <v-slider v-model="cilindro_od" step="0.25" :max="max5" :min="min5" dense thumb-label
                            :thumb-size="tamano_letra">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement('cilindro')">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment('cilindro')">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-subheader class="ml-n7 mb-n3 mt-n8" style="font-size:80%;">Eje</v-subheader>
                        <v-slider :disabled="this.cilindro_od == 0" v-model="eje_od" step="1" :max="max4" :min="min4"
                            dense thumb-label :thumb-size="tamano_letra">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement('eje')">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment('eje')">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-subheader class="ml-n7 mb-n3 mt-n8" style="font-size:80%;">D.N.P</v-subheader>
                        <v-slider v-model="dnp_od" step="0.50" :max="max1" :min="min1" dense thumb-label
                            @click="foco_dnp = true" :thumb-size="tamano_letra">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement('dnp'), foco_dnp = true">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment('dnp'), foco_dnp = true">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field class="mb-n5" type="number" outlined dense v-model="av_od"
                                    label="A/V"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field class="mb-n5" type="number" outlined dense v-model="pio_od" label="PIO"
                                    @keyup.enter="panel = 1"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="mt-2">
                    <v-expansion-panel-header class="mt-n2">
                        <v-row>
                            <v-col cols="6" class="pa-2" style="font-size:80%;">
                                <v-icon class="mt-n1" color="red" dark small>mdi-eye-outline</v-icon> O.I.
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-row class="mt-n4 pa-1 text-center red--text" dense style="font-size:80%;">
                        <v-col cols="2">
                            Esfe.
                        </v-col>
                        <v-col cols="2">
                            Cili.
                        </v-col>
                        <v-col cols="8">
                            <v-row class="mt-n2 pa-1 text-center red--text" dense>
                                <v-col cols="3">
                                    Eje
                                </v-col>
                                <v-col cols="3">
                                    DNP
                                </v-col>
                                <v-col cols="3">
                                    A/V
                                </v-col>
                                <v-col cols="3">
                                    PIO
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n4 pa-1 text-center" dense style="font-size:80%;">
                        <v-col cols="2">
                            {{ devuelve_simbolo(esfera_oi) }}
                        </v-col>
                        <v-col cols="2">
                            {{ devuelve_simbolo(cilindro_oi) }}
                        </v-col>
                        <v-col cols="8">
                            <v-row class="mt-n2 pa-1 text-center" dense>

                                <v-col cols="3">
                                    {{ eje_oi }}°
                                </v-col>
                                <v-col cols="3">
                                    {{ dnp_oi }}mm
                                </v-col>
                                <v-col cols="3">
                                    20/{{ av_oi }}
                                </v-col>
                                <v-col cols="3">
                                    {{ pio_oi }}mmHg
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-expansion-panel-content class="mt-n1" id="id_od">
                        <v-subheader class="ml-n7 mb-n3" style="font-size:80%;">Esfera</v-subheader>
                        <v-slider v-model="esfera_oi" step="0.25" :max="max" :min="min" thumb-label
                            :thumb-size="tamano_letra">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement_i('esfera')">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment_i('esfera')">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-subheader class="ml-n7 mb-n3 mt-n8" style="font-size:80%;">Cilindro</v-subheader>
                        <v-slider v-model="cilindro_oi" step="0.25" :max="max5" :min="min5" thumb-label
                            :thumb-size="tamano_letra">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement_i('cilindro')">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment_i('cilindro')">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-subheader class="ml-n7 mb-n3 mt-n8" style="font-size:80%;">Eje</v-subheader>
                        <v-slider :disabled="this.cilindro_oi == 0" v-model="eje_oi" step="1" :max="max4" :min="min4"
                            thumb-label :thumb-size="tamano_letra">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement_i('eje')">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment_i('eje')">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-subheader class="ml-n7 mb-n3 mt-n8" style="font-size:80%;">D.N.P</v-subheader>
                        <v-slider v-model="dnp_oi" step="0.50" :max="max1" :min="min1" dense thumb-label
                            :thumb-size="tamano_letra" @click="foco_dnp = true">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement_i('dnp'), foco_dnp = true">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment_i('dnp'), foco_dnp = true">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-row>
                            <v-col cols="6">

                                <v-text-field class="id_od mb-n5" type="number" outlined dense v-model="av_oi"
                                    label="A/V"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field class="mb-n5" type="number" outlined dense v-model="pio_oi" label="PIO"
                                    @keyup.enter="panel = 2"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="mt-2">
                    <v-expansion-panel-header class="mt-n2">
                        <v-row>
                            <v-col cols="6" class="pa-2">
                                <v-icon class="mt-n1" color="red" dark small>mdi-eye-outline</v-icon>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-row class="mt-n4 pa-1 text-center red--text" dense style="font-size:80%;">

                        <v-col cols="4">
                            ADD CERCA
                        </v-col>
                        <v-col cols="4">
                            ADD INTERM.
                        </v-col>
                        <v-col cols="4">
                            DIP
                        </v-col>
                    </v-row>
                    <v-row class="mt-n4 pa-1 text-center" dense style="font-size:80%;">

                        <v-col cols="4">
                            {{ devuelve_simbolo(add) }}
                        </v-col>
                        <v-col cols="4">
                            {{ devuelve_simbolo(add_int) }}
                        </v-col>
                        <v-col cols="4">
                            {{ dip }} mm
                        </v-col>
                    </v-row>
                    <v-expansion-panel-content class="mt-n1">

                        <v-slider v-model="add" class="align-center" step="0.25" :max="max2" :min="min2" hide-details
                            :thumb-size="tamano_letra" thumb-label>
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement('add')">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment('add')">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-slider v-model="add_int" class="align-center" step="0.25" :max="max_add_int"
                            :min="min_add_int" hide-details :thumb-size="tamano_letra" thumb-label>
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement('add_int')">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment('add_int')">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                        <v-slider v-model="dip" class="align-center" step="0.50" :max="max3" :min="min3" hide-details
                            :thumb-size="tamano_letra" thumb-label @click="foco_dnp = false">
                            <template v-slot:prepend>
                                <v-icon large color="red" @click="decrement('dip'), foco_dnp = false">
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon large color="green" @click="increment('dip'), foco_dnp = false">
                                    mdi-plus
                                </v-icon>
                            </template>
                            <template v-slot:thumb-label="{ value }">
                                <h2>{{ value }}</h2>
                            </template>
                        </v-slider>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-col cols="12" class="mt-1">
                <v-textarea :readonly="red_comentario" outlined dense v-model="comentarios" auto-grow filled
                    label="Comentarios" rows="1" @click="red_comentario = false"
                    @blur="red_comentario = true"></v-textarea>
            </v-col>
            <v-col cols="12" class="mt-n8">
                <v-select style="font-size:75%;" :items="array_optometra" label="Optometra" dense outlined
                    v-model="especialista"></v-select>
                <p style="font-size:75%;" class="red--text mt-n6 ml-2" v-if="primer_especialista != ''"> Antes : {{
        primer_especialista }}</p>
            </v-col>
            <v-col cols="12" class="mt-n8 mb-6">
                <v-card class="pa-6">
                    <v-row no-gutters class="mt-n2 mb-n3">
                        <v-col cols="10">
                            <v-row v-for="item in array_recom" :key="item.numeracion">
                                <v-icon color="red" right small class="mb-2" @click="elimina_recom(item)">
                                    mdi-cancel
                                </v-icon>
                                <p v-if="!item.comprado" style="font-size:80%;" class="mb-2">
                                    {{ item.numeracion }}) {{ item.marca }}/{{ item.descripcion }}</p>
                                <u v-if="item.comprado" style="font-size:80%;"> {{ item.numeracion }}) {{ item.marca
                                    }}/{{
        item.descripcion
    }} </u>
                            </v-row>
                        </v-col>
                        <v-col cols="2">
                            <v-btn class="mt-n2" color="info" block small @click="dial_recomendaciones = true">
                                Recom.
                            </v-btn>
                            <v-btn class="mt-1" v-if="array_compra != ''" color="warning" block small
                                @click="dial_compras = true">
                                Comprado
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-row class="mt-n6" dense>
                <v-col cols="4">
                    <v-btn :disabled="key_cita != ''" class="" color=" info" block medium @click="abre_proxima_cita()">
                        PROX.CITA
                        <v-icon dark right medium>
                            mdi-calendar-alert
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <v-btn :disabled="!control || especialista == '' || key_cita == ''" class="" color="error" block
                        medium @click="Guarda_medicion('CONTROL', true)">
                        CONTROL
                        <v-icon dark right medium>
                            mdi-calendar-alert
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <v-btn :disabled="especialista == '' || !guard_rec || key_cita == '' || array_recom == ''"
                        color="success" block medium @click="Guarda_medicion('RECETA', true)">
                        GUARDAR RECETA
                        <v-icon dark right medium>
                            mdi-content-save
                        </v-icon>
                    </v-btn>
                </v-col>

            </v-row>
        </v-card>
        <v-dialog v-model="dialog_tiendas" scrollable max-width="500px">
            <v-card>
                <h4 class="text-center">SELECCIONE TIENDA</h4>
                <v-simple-table class="elevation-1 mt-2" fixed-header height="70vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Tienda
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for=" item  in  $store.state.array_tiendas " :key="item.id"
                                @click="select_tienda(item)">
                                <td>{{ item.nom_tienda }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_compras" max-width="500px">
            <div>
                <v-system-bar window dark dense>
                    <v-icon @click="dial_compras = !dial_compras">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-4">
                <v-row v-for="item in array_compra" :key="item.numeracion">
                    <p style="font-size:90%;" class="mb-2">
                        {{ item.id_pos + 1 }}) {{ item.nombre }} </p>
                </v-row>
            </v-card>
        </v-dialog>
        <nuevo_cli v-if="dial_cliente" :dial_cliente="dial_cliente" @cierra="dial_cliente = $event"
            @cliente="selecciona_cliente($event)" />
        <lista_productos v-if="dial_recomendaciones" :dial="dial_recomendaciones" @array="agrega_productos($event)"
            @cierra="dial_recomendaciones = false" :edicion="false" :serie="obten_serie()" />
        <prox_cita v-if="dial_prox_cita" :mes=1 :data="cliente" @cierra=" asigna_key('')"
            @id_cita="asigna_key($event)" />
        <historial_med v-if="abre_historial" :data="data_historial" @cierra="abre_historial = false" />
    </v-dialog>
</template>

<script>
import nuevo_cli from '@/views/optometria/busca_cliente'
import lista_productos from '@/components/almacen/lista_productos'
import prox_cita from '@/components/dialogos/dialog_prox_cita'
import historial_med from '@/components/dialogos/dialog_historial_med'
import moment from 'moment'
import store from '@/store/index'
import {
    nuevo_medicion,
    elimina_cita,
    busca_citas,
    busca_tabla,
    nueva_garantia_p,
    nueva_cabecera_parametro,
    allpedidos,
    edita_pendientes_parametro,
    edita_cabecera_parametro
} from '../../db'
export default {
    components: {
        nuevo_cli,
        lista_productos,
        prox_cita,
        historial_med
    },
    props: {
        data: '',
        client: '',
        ultima_med: ''
    },
    name: 'caja',
    data() {
        return {
            red_comentario: true,
            data_historial: [],
            tamano_letra: 80,
            abre_historial: false,
            dial_ultima_med: false,
            dial_prox_cita: false,
            dial_recomendaciones: false,
            dial_activo: false,
            dial_cliente: false,
            dial_compras: false,
            cliente: [],
            tienda: '',
            esfera_od: 0,
            cilindro_od: 0,
            eje_od: 0,
            dnp_od: 25,
            av_od: '',
            pio_od: '',
            esfera_oi: 0,
            cilindro_oi: 0,
            eje_oi: 0,
            dnp_oi: 25,
            av_oi: '',
            pio_oi: '',
            add: 0,
            add_int: 0,
            dip: 0,
            min: -25,
            max: 25,
            min1: 25,
            max1: 40,
            min2: 0,
            max2: 6,
            min3: 50,
            max3: 80,
            min4: 0,
            max4: 180,
            min5: -8,
            max5: 0,
            min_add_int: 0,
            max_add_int: 10,

            seasons: [
                'Figs',
                'Lemon',
                'Pear',
                'Apple',
            ],
            disabled: false,
            panel: [],
            comentarios: '',
            recomendaciones: '',
            especialista: '',
            foco_dnp: true,
            id: '',
            array_recom: [],
            array_optometra: [],
            primer_especialista: '',
            control: false,
            guard_rec: false,
            key_cita: '',
            array_compra: [],
            reprograma_cita: false,
            dialog_tiendas: false,
        }
    },
    created() {
        this.tienda = this.client.tienda
        //console.log(Boolean(this.client.control))
        if (Boolean(this.client.control)) {
            this.control = true
        }
        if (Boolean(this.client.receta)) {
            this.guard_rec = true
        }
        this.ini()
        if (this.client != '') {
            this.cliente = this.client

        }
        if (this.ultima_med != '') {
            //    console.log(this.ultima_med.length)
            this.data_historial = this.ultima_med[this.ultima_med.length - 1]
        }
        console.log(this.data)
        if (this.data != '') {
            this.id = this.data.id
            this.cliente.nombre = this.data.nom_cliente
            this.cliente.documento = this.data.dni_cliente
            this.cliente.tienda = this.data.tienda
            this.tienda = this.data.tienda
            this.especialista = this.data.especialista
            this.comentarios = this.data.comentarios
            this.recomendaciones = this.data.recomendaciones
            this.esfera_od = this.data.esfera_od
            this.esfera_oi = this.data.esfera_oi
            this.cilindro_od = this.data.cilindro_od
            this.cilindro_oi = this.data.cilindro_oi
            this.eje_od = this.data.eje_od
            this.eje_oi = this.data.eje_oi
            this.dnp_od = this.data.dnp_od
            this.dnp_oi = this.data.dnp_oi
            this.av_od = this.data.av_od
            this.av_oi = this.data.av_oi
            this.pio_od = this.data.pio_od
            this.pio_oi = this.data.pio_oi
            this.add = this.data.add
            this.add_int = this.data.add_int
            this.dip = this.data.dip
            this.array_recom = this.data.array_recom
            if (this.data.key_cita != undefined) {
                this.key_cita = this.data.key_cita
            }
            if (this.data.array_compra != undefined) {
                this.array_compra = this.data.array_compra
            }
            if (this.data.primer_especialista != undefined) {
                this.primer_especialista = this.data.primer_especialista
            }
        } else {
            this.id = ''
        }
    },
    computed: {
        suma_DNP() {
            var decimal = this.add - Math.trunc(this.add)
            if (this.add >= 1) {
                if (decimal == 0.25 || decimal == 0.75) {
                    this.min_add_int = (this.add + 0.25) / 2
                }
            }

            if (this.add != 0) {
                this.max_add_int = this.add - 0.25
            }

            if (this.foco_dnp) {
                this.dip = this.dnp_od + this.dnp_oi
            } else {
                this.dnp_od = this.dip / 2
                this.dnp_oi = this.dip / 2
            }

            var total = this.precioedita * (this.porcentaje / 100)

            return ''
        }
    },
    methods: {
        select_tienda(data) {
            this.tienda = data.nom_tienda
            this.cliente.tienda = data.nom_tienda
            this.dialog_tiendas = false
        },
        async asigna_key(value) {
            this.key_cita = value
            this.dial_prox_cita = false
            if (this.reprograma_cita) {
                this.Guarda_medicion('RECETA', false)
            }
        },
        async ini() {
            var snapshot = await busca_tabla('especialistas').once("value")
            snapshot.forEach((item) => {
                let data = item.val();
                this.array_optometra[this.array_optometra.length] = data.nombre
            });
            this.dial_activo = true
        },
        decrement(modo) {
            if (modo == 'esfera') {
                this.esfera_od = this.esfera_od - 0.25
            }
            if (modo == 'cilindro') {
                this.cilindro_od = this.cilindro_od - 0.25
            }
            if (modo == 'eje') {
                this.eje_od--
            }
            if (modo == 'dnp') {
                this.dnp_od = this.dnp_od - 0.50
            }
            if (modo == 'add') {
                this.add = this.add - 0.25
            }
            if (modo == 'add_int') {
                this.add_int = this.add_int - 0.25
            }
            if (modo == 'dip') {
                this.foco_dnp = false
                this.dip = this.dip - 0.50
            }

        },
        increment(modo) {
            if (modo == 'esfera') {
                this.esfera_od = this.esfera_od + 0.25
            }
            if (modo == 'cilindro') {
                this.cilindro_od = this.cilindro_od + 0.25
            }
            if (modo == 'eje') {
                this.eje_od++
            }
            if (modo == 'dnp') {
                this.dnp_od = this.dnp_od + 0.50
            }
            if (modo == 'add') {
                this.add = this.add + 0.25
            }
            if (modo == 'add_int') {
                this.add_int = this.add_int + 0.25
            }
            if (modo == 'dip') {
                this.foco_dnp = false
                this.dip = this.dip + 0.50
            }
        },
        decrement_i(modo) {
            if (modo == 'esfera') {
                this.esfera_oi = this.esfera_oi - 0.25
            }
            if (modo == 'cilindro') {
                this.cilindro_oi = this.cilindro_oi - 0.25
            }
            if (modo == 'eje') {
                this.eje_oi--
            }
            if (modo == 'dnp') {
                this.dnp_oi = this.dnp_oi - 0.50
            }

        },
        increment_i(modo) {
            if (modo == 'esfera') {
                this.esfera_oi = this.esfera_oi + 0.25
            }
            if (modo == 'cilindro') {
                this.cilindro_oi = this.cilindro_oi + 0.25
            }
            if (modo == 'eje') {
                this.eje_oi++
            }
            if (modo == 'dnp') {
                this.dnp_oi = this.dnp_oi + 0.50
            }
        },
        diagnostico() {
            var diagnostico = "EMETROPE"
            if (this.esfera_od >= 0 && this.esfera_oi > 0) {
                diagnostico = 'HIPERMETROPIA'
            }
            if (this.esfera_od > 0 && this.esfera_oi >= 0) {
                diagnostico = 'HIPERMETROPIA'
            }
            if (this.esfera_od <= 0 && this.esfera_oi < 0) {
                diagnostico = 'MIOPIA'
            }
            if (this.esfera_od < 0 && this.esfera_oi <= 0) {
                diagnostico = 'MIOPIA'
            }
            if (this.esfera_od > 0 && this.esfera_oi < 0) {
                diagnostico = 'ANISOMETROPIA'
            }
            if (this.esfera_od < 0 && this.esfera_oi > 0) {
                diagnostico = 'ANISOMETROPIA'
            }
            // cuando el ojo izquierdo esta en 0
            if (this.esfera_od == 0 && this.esfera_oi == 0 && this.cilindro_od < 0 && this.cilindro_oi <= 0) {
                diagnostico = 'ASTIGMATISMO'
            }
            if (this.esfera_od > 0 && this.esfera_oi >= 0 && this.cilindro_od < 0 && this.cilindro_oi <= 0) {
                diagnostico = 'HIPERMETROPIA/ASTIGMATISMO'
            }
            if (this.esfera_od < 0 && this.esfera_oi <= 0 && this.cilindro_od < 0 && this.cilindro_oi <= 0) {
                diagnostico = 'MIOPIA/ASTIGMATISMO'
            }
            ///// cuando el ojo derecho esta en 0
            if (this.esfera_od == 0 && this.esfera_oi == 0 && this.cilindro_od <= 0 && this.cilindro_oi < 0) {
                diagnostico = 'ASTIGMATISMO'
            }
            if (this.esfera_od >= 0 && this.esfera_oi > 0 && this.cilindro_od <= 0 && this.cilindro_oi < 0) {
                diagnostico = 'HIPERMETROPIA/ASTIGMATISMO'
            }
            if (this.esfera_od <= 0 && this.esfera_oi < 0 && this.cilindro_od <= 0 && this.cilindro_oi < 0) {
                diagnostico = 'MIOPIA/ASTIGMATISMO'
            }

            if (this.esfera_od < 0 && this.esfera_oi > 0 && this.cilindro_od < 0 && this.cilindro_oi < 0) {
                diagnostico = 'ANISOMETROPIA/ASTIGMATISMO'
            }
            if (this.esfera_od > 0 && this.esfera_oi < 0 && this.cilindro_od < 0 && this.cilindro_oi < 0) {
                diagnostico = 'ANISOMETROPIA/ASTIGMATISMO'
            }
            if (this.add > 0) {
                diagnostico = diagnostico + '/PRESBICIA'
            }
            return diagnostico
        },
        async Guarda_medicion(value, act_gara) {
            store.commit("dialogoprogress", 1)
            if (this.id != '') {
                var fecha = this.id
            } else {
                var fecha = moment().unix()
            }
            var editado = false
            if (this.data.editado) {
                editado = true
            }
            if (this.data.estado == "ATENDIDO") {
                editado = true
            }
            if (value == 'CONTROL') {
                var estado = 'CONTROL'
                var modo = 'CONTROL'
            } else {
                var estado = 'PENDIENTE'
                var modo = 'RECETA'
            }
            var array = {
                id: fecha,
                nom_cliente: this.cliente.nombre,
                dni_cliente: this.cliente.documento,
                tienda: this.cliente.tienda,
                especialista: this.especialista,
                fecha: moment().unix(),
                diagnostico: this.diagnostico(),
                comentarios: this.comentarios.toUpperCase(),
                recomendaciones: this.recomendaciones,
                esfera_od: this.devuelve_simbolo(this.esfera_od),
                esfera_oi: this.devuelve_simbolo(this.esfera_oi),
                cilindro_od: this.devuelve_simbolo(this.cilindro_od),
                cilindro_oi: this.devuelve_simbolo(this.cilindro_oi),
                eje_od: this.eje_od,
                eje_oi: this.eje_oi,
                dnp_od: this.dnp_od,
                dnp_oi: this.dnp_oi,
                av_od: this.av_od,
                av_oi: this.av_oi,
                pio_od: this.pio_od,
                pio_oi: this.pio_oi,
                add: this.devuelve_simbolo(this.add),
                add_int: this.devuelve_simbolo(this.add_int),
                dip: this.dip,
                estado: estado,
                editado: editado,
                array_recom: this.array_recom,
                primer_especialista: this.primer_especialista,
                modo: modo,
                key_cita: this.key_cita,
                array_compra: this.array_compra,
                serie: this.obten_serie()
            }
            if (editado && act_gara) {
                this.genera_garantia(array)
            }
            await nuevo_medicion(array.id, array)
            store.commit("dialogoprogress", 1)
            this.cierra(array.editado, array)

        },
        async genera_garantia(array) {
            console.log(array)
            var d = array.array_compra

            var fecha = moment().unix()
            for (var i = 0; i < d.length; i++) {
                console.log(d[i])
                if (d[i].tipo_focal.includes('DIGITAL')) {
                    var snap = await allpedidos().orderByChild('id_receta').equalTo(array.id).once('value')
                    var tt = []
                    snap.forEach((item) => {
                        tt = item.val()
                    })
                    console.log(tt)
                    edita_pendientes_parametro(tt.id, 'estado', 'PENDIENTE')
                    await edita_cabecera_parametro(tt.cliente_dni, tt.id, 'estado', 'PENDIENTE')
                }
                var arr = {
                    fecha: fecha,
                    nom_cliente: this.cliente.nombre,
                    dni_cliente: this.cliente.documento,
                    producto: d[i],
                    id_receta: array.id,
                    id_parametro: '',
                    modo: 'RECETA',
                    estado: 'PENDIENTE',
                    espe: array.especialista,
                    espe_ant: array.primer_especialista
                }
                await nueva_garantia_p(arr)
            }

        },
        termina() {
            this.$emit('termina', false)
        },
        async cierra(value, array) {
            if (value && !this.reprograma_cita) {
                var snap = await busca_citas(array.key_cita).once('value')
                var fech = moment.unix(snap.val().inicio).format('DD/MM/YY hh:mm A')
                if (confirm('El paciente tiene una cita para el ' + fech + ' desea reprogramarla?')) {
                    await elimina_cita(array.key_cita)
                    this.reprograma_cita = true
                    this.dial_prox_cita = true
                } else {
                    this.reprograma_cita = false
                    this.$emit('cierra', false)
                }
            } else {
                this.reprograma_cita = false
                this.$emit('cierra', false)
            }

        },
        selecciona_cliente(data) {
            this.cliente = data
            this.dial_cliente = false
        },
        devuelve_simbolo(data) {
            var simbol = ""
            if (data > 0) {
                simbol = "+"
            }
            return simbol + parseFloat(data).toFixed(2)
        },
        agrega_productos(data) {
            var numeracion = this.array_recom.length + 1
            if (this.recomendaciones == '') {
                this.recomendaciones = numeracion + ') ' + data.marca + ' / ' + data.descripcion
            } else {
                this.recomendaciones = this.recomendaciones + '\n' + numeracion + ') ' + data.marca + ' / ' + data.descripcion
            }
            data.numeracion = numeracion
            data.chip = true
            if (data.tipo_focal == undefined) {
                data.tipo_focal = ''
            }
            console.log(data)
            this.array_recom.push(data)
        },
        abre_proxima_cita() {
            this.dial_prox_cita = true
        },
        copia_histo() {
            var data = this.data_historial
            this.cliente.nombre = data.nom_cliente
            this.cliente.documento = data.dni_cliente
            this.cliente.tienda = data.tienda
            this.tienda = data.tienda
            this.especialista = data.especialista
            this.comentarios = data.comentarios
            this.esfera_od = data.esfera_od
            this.esfera_oi = data.esfera_oi
            this.cilindro_od = data.cilindro_od
            this.cilindro_oi = data.cilindro_oi
            this.eje_od = data.eje_od
            this.eje_oi = data.eje_oi
            this.dnp_od = data.dnp_od
            this.dnp_oi = data.dnp_oi
            this.av_od = data.av_od
            this.av_oi = data.av_oi
            this.pio_od = data.pio_od
            this.pio_oi = data.pio_oi
            this.add = data.add
            this.add_int = data.add_int
            this.dip = data.dip
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YY')
        },
        siguiente(next) {
            document.getElementById(next).focus();
        },
        elimina_recom(item) {
            this.array_recom.splice(item.numeracion - 1, 1)
            this.array_recom.map(function (it, indice) {
                it.numeracion = indice + 1
            })
        },
        regresa_serie(data) {
            if (data == 1) {
                return '1ERA'
            }
            if (data == 2) {
                return '2DA'
            }
            if (data == 3) {
                return '3ERA'
            }
            if (data == 4) {
                return 'FABRICACION'
            }
        },
        obten_serie() {
            var serie_od = 1
            var serie_oi = 1
            if (this.esfera_od <= 6 && this.esfera_od >= -6) {
                if (this.cilindro_od <= 0 && this.cilindro_od >= -2) {
                    serie_od = 1
                }
                if (this.cilindro_od <= -2.25 && this.cilindro_od >= -4) {
                    serie_od = 2
                }
                if (this.cilindro_od <= -4.25 && this.cilindro_od >= -6) {
                    serie_od = 3
                }
                if (this.cilindro_od <= -6.25) {
                    serie_od = 4
                }
            } else {
                serie_od = 4
            }
            if (this.esfera_oi <= 6 && this.esfera_oi >= -6) {
                if (this.cilindro_oi <= 0 && this.cilindro_oi >= -2) {
                    serie_oi = 1
                }
                if (this.cilindro_oi <= -2.25 && this.cilindro_oi >= -4) {
                    serie_oi = 2
                }
                if (this.cilindro_oi <= -4.25 && this.cilindro_oi >= -6) {
                    serie_oi = 3
                }
                if (this.cilindro_oi <= -6.25) {
                    serie_oi = 4
                }
            } else {
                serie_oi = 4
            }
            if (serie_od <= serie_oi) {
                return this.regresa_serie(serie_oi)
            } else {
                return this.regresa_serie(serie_od)
            }
        },
        cambiar() {
            this.$emit('cambiar', true)
        }
    },

}
</script>

<style>
.scroll {
    overflow-y: scroll
}
</style>
