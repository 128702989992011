import { render, staticRenderFns } from "./pendientes.vue?vue&type=template&id=26200fd2&scoped=true&"
import script from "./pendientes.vue?vue&type=script&lang=js&"
export * from "./pendientes.vue?vue&type=script&lang=js&"
import style0 from "./pendientes.vue?vue&type=style&index=0&id=26200fd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26200fd2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VAutocomplete,VBtn,VCard,VCardText,VCol,VContainer,VDialog,VDivider,VIcon,VImg,VRow,VSelect,VSimpleTable,VSlider,VSpacer,VSystemBar,VTextField})
