<template>
    <div class="pa-4">
        <v-row class="mb-n5 mt-n2" dense>
            <v-col cols="7">
                <h4>GARANTIAS DE PRODUCTOS</h4>
                <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n3' : 'mb-4 mt-n4'">
                    <v-col cols="6">
                        <v-card elevation="12" class="mt-3" color="white" @click="abre_lista_pendientes()">
                            <v-container class="mb-n12 ml-1">
                                <v-badge :content="num_garantias" :value="num_garantias" color="green" overlap
                                    class="ml-10">
                                </v-badge>
                            </v-container>
                            <v-container>
                                <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/time.png"></v-img>
                                <p class="text-center mb-n2 mt-2">GARANTIAS</p>
                            </v-container>

                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card elevation="12" class="mt-3" color="white" @click="selecciona_reclamos()">
                            <v-container>
                                <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/new.png"></v-img>
                                <p class="text-center mb-n2 mt-2">RECLAMOS</p>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5">
                <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mb-n7 mt-6 text-center'">
                    <v-col cols="12" md="6" xs="12">
                        <v-text-field type="date" class="redondeado" outlined dense v-model="date1"
                            label="INICIO"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" xs="12">
                        <v-text-field type="date" class="redondeado" outlined dense v-model="date2"
                            label="FIN"></v-text-field>
                    </v-col>

                </v-row>
            </v-col>
        </v-row>
        <v-card class="mt-2">
            <v-simple-table fixed-header height="70vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                N° Garantia
                            </th>
                            <th class="text-left">
                                Fecha
                            </th>
                            <th class="text-left">
                                Modo
                            </th>
                            <th class="text-left">
                                Cliente
                            </th>
                            <th class="text-left">
                                Producto
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in lista_garantias" :key="item.id">
                            <td style="font-size:85%;">{{ item.id }}</td>
                            <td style="font-size:85%;">{{ conviertefecha(item.fecha) }}</td>
                            <td style="font-size:85%;">{{ item.modo }}</td>
                            <td style="font-size:85%;">{{ item.cliente_nombre }}</td>
                            <td style="font-size:85%;">{{ item.producto.nombre }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-dialog v-model="dial_garantia" max-width="500">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_garantia = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>GENERA GARANTIA</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <p><strong>Producto : </strong> {{ producto }}</p>
                <v-textarea outlined dense v-model="observacion" auto-grow filled label="OBSERVACION" rows="1"></v-textarea>
                <v-row class="mt-n4" dense>
                    <v-col cols="6">
                        <v-card @click.prevent="genera_garantia('RECLAMO')">
                            <v-container>
                                <h6 block class="text-center">RECLAMO</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click.prevent="genera_garantia_interna()">
                            <v-container>
                                <h6 block class="text-center">GARANTIA</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_lista_pedidos" max-width="850">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_lista_pedidos = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-text-field autofocus style="font-size:85%;" outlined dense v-model="buscar"
                    label="buscar"></v-text-field>
                <v-simple-table fixed-header height="50vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    N° Pedido
                                </th>
                                <th class="text-left">
                                    Cliente
                                </th>
                                <th class="text-left">
                                    Producto
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id" @click="selecciona_reclamo(item)">
                                <td style="font-size:85%;">{{ item.id }}</td>
                                <td style="font-size:85%;">{{ item.cliente_nombre }}</td>
                                <td style="font-size:85%;">{{ item.producto.nombre }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_externa" max-width="600">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_externa = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>SELECCIONA UN RECETA</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4>PRODUCTO VENDIDO CON RECETA EXTERNA, DEBE SELECCIONAR UNA INTERNA</h4>
                <v-simple-table class="elevation-1 mt-2" fixed-header height="40vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Paciente
                                </th>
                                <th class="text-left">
                                    Diagnostico
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in lista_recetas" :key="item.id" @click="selec_paciente(item)">
                                <td>{{ item.nom_cliente }}</td>
                                <td>{{ item.diagnostico }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_garantias" max-width="950">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_garantias = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-simple-table fixed-header height="50vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Cliente
                                </th>
                                <th class="text-left">
                                    Modo
                                </th>
                                <th class="text-left">
                                    Producto
                                </th>
                                <th class="text-left">
                                    Primer Especialista
                                </th>
                                <th class="text-left">
                                    Especialista
                                </th>
                                <th class="text-left">
                                    fecha
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_pendientes" :key="item.id" @click="selecciona_garantia(item)">
                                <td style="font-size:80%;">{{ item.nom_cliente }}</td>
                                <td style="font-size:80%;">{{ item.modo }}</td>
                                <td style="font-size:80%;">{{ item.producto.nombre }}</td>
                                <td style="font-size:80%;">{{ item.espe_ant }}</td>
                                <td style="font-size:80%;">{{ item.espe }}</td>
                                <td style="font-size:80%;">{{ conviertefecha(item.fecha) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    all_garantias,
    allpedidos,
    nueva_garantia,
    nuevo_pedido,
    obtenContador_externo,
    actualizaContador_externo,
    busca_medicion,
    edita_garantias_p,
    edita_medicion,
    all_mediciones,
    all_garantias_p,
} from '../../db'
import moment from 'moment'
import store from '@/store/index'
import {
    reporte_parametro
} from '../../genera_pedido'
export default {

    data() {
        return {
            dial_garantias: false,
            dial_garantia: false,
            dial_lista_pedidos: false,
            dial_externa: false,
            date1: moment(String(new Date)).format('YYYY-MM-DD'),
            date2: moment(String(new Date)).format('YYYY-MM-DD'),
            numero_pedido: '',
            item_selecto: [],
            array_pedidos: [],
            buscar: '',
            observacion: '',
            desserts: [],
            producto: '',
            array_recetas: [],
            num_garantias: 0,
            array_pendientes: []
        }
    },
    created() {
        this.inicio()
    },
    mounted() {
        all_garantias().on("value", this.onDataChange);
        all_garantias_p().on("value", this.onDataChange_);
    },
    destroyed() {
        all_garantias().off("value", this.onDataChange);
        all_garantias_p().off("value", this.onDataChange_);
    },
    computed: {
        listafiltrada() {
            return this.array_pedidos.filter((item) => (item.id + item.cliente_nombre + item.cliente_dni + item.producto.nombre)
                .toLowerCase().includes(this.buscar.toLowerCase())).reverse()
        },
        lista_garantias() {
            all_garantias().on("value", this.onDataChange);
            return this.desserts
        },
        lista_recetas() {
            return this.array_recetas
        },
    },
    methods: {
        inicio() {
            var dia = moment(String(new Date)).format('DD')
            this.date1 = moment().subtract(parseFloat(dia) - 1, 'd').format('YYYY-MM-DD')
            this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.desserts = array;
        },
        onDataChange_(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                let key = item.key
                data.key = key
                if (data.estado == 'PENDIENTE')
                    array.push(data);
            });
            this.num_garantias = array.length
            this.array_pendientes = array
        },
        async abre_lista_pendientes() {
            this.dial_garantias = true
        },
        async Busca_pedido() {
            store.commit("dialogoprogress", 1)
            this.buscar = ''
            this.array_pedidos = []
            var snap = await allpedidos().orderByChild('estado').equalTo('ACEPTADO').once('value')
            if (snap.exists()) {
                snap.forEach((item) => {
                    this.array_pedidos.push(item.val())
                })
                this.dial_lista_pedidos = true
            } else {
                alert('SIN INFORMACION')
            }
            store.commit("dialogoprogress", 1)
        },
        selecciona_reclamo(item) {
            if (confirm("Seguro que desea generar un nuevo reclamo")) {
                this.item_selecto = item
                this.genera_garantia('RECLAMO')
            }
            this.dial_lista_pedidos = false
        },
        async genera_garantia(modo) {
            store.commit("dialogoprogress", 1)
            var cabecera = this.item_selecto
            var contador = await obtenContador_externo().once("value")
            var array_cabecera = {
                id: contador.val().orden_pedidos,
                fecha: moment().unix(),
                estado: 'PENDIENTE',
                tienda: store.state.permisos.tienda,
                bd: store.state.baseDatos.bd,
                doc_venta: cabecera.doc_venta,
                comprobante: cabecera.comprobante,
                total: cabecera.total,
                costo: cabecera.costo,
                a_cuenta: cabecera.total,
                modo_receta: cabecera.modo_receta,
                id_receta: cabecera.id_receta,
                id_compra: '',
                comentario: 'PRODUCTO POR ' + modo,
                vendedor: cabecera.vendedor,
                producto: cabecera.producto,
                cliente_dni: cabecera.cliente_dni,
                cliente_nombre: cabecera.cliente_nombre,
                id_a_cuenta: '',
                garantia_reclamos: true
            }

            var array_garantia = {
                id: contador.val().orden_garantias,
                fecha: moment().unix(),
                comentario: this.observacion,
                cliente_dni: cabecera.cliente_dni,
                cliente_nombre: cabecera.cliente_nombre,
                id_receta: cabecera.id_receta,
                producto: cabecera.producto,
                vendedor: cabecera.vendedor,
                modo_receta: cabecera.modo_receta,
                modo: modo
            }
            if (!Boolean(array_cabecera.producto.id_parametro)) {
                reporte_parametro(array_cabecera)
            }
            nueva_garantia(array_garantia.id, array_garantia)
            await nuevo_pedido(array_cabecera.id, array_cabecera)
            var valor = (parseInt(array_cabecera.id) + 1).toString().padStart(8, 0)
            await actualizaContador_externo('orden_pedidos', valor)
            var valor2 = (parseInt(array_garantia.id) + 1).toString().padStart(8, 0)
            await actualizaContador_externo('orden_garantias', valor2)
            this.dial_garantia = false
            store.commit("dialogoprogress", 1)
        },
        async genera_garantia_interna() {
            var item = this.item_selecto
            if (item.modo_receta == 'INTERNA') {
                var snap = await busca_medicion(item.id_receta).once("value")
                if (snap.val().editado) {
                    if (confirm("DESEA SOLICITAR NUEVOS PARAMETROS?")) {
                        this.item_selecto.producto.id_parametro = ''
                    }
                    edita_medicion(this.item_selecto.id_receta, 'estado', 'ATENDIDO')
                    this.genera_garantia('GARANTIA')
                } else {
                    alert('LA RECETA INTERNA AUN NO HA SIDO EDITADA')
                }
            } else {
                var snap = await all_mediciones().orderByChild('dni_cliente').equalTo(item.cliente_dni).once("value");
                snap.forEach((item) => {
                    let data = item.val();
                    if (data.estado == 'PENDIENTE') {
                        this.array_recetas.push(data);
                    }
                });
                this.dial_externa = true
            }
        },
        selec_paciente(item) {
            this.item_selecto.id_receta = item.id
            this.item_selecto.modo_receta = 'INTERNA'
            this.genera_garantia('GARANTIA')
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY')
        },
        async selecciona_garantia(items) {
            console.log(items)
            this.dial_garantias = false
            if (confirm("Seguro que desea generar un nuevo pedido por garantia")) {
                if (items.modo == 'RECETA') {
                    var snap = await allpedidos().orderByChild('id_receta').equalTo(items.id_receta).once('value')
                    if (snap.exists()) {
                        snap.forEach((item) => {
                            var data = item.val()
                            console.log(data.producto, items)
                            if (data.producto.nombre == items.producto.nombre) {
                                this.item_selecto = data
                            }
                        })
                        console.log(this.item_selecto)
                        edita_garantias_p(items.key + '/estado', 'ACEPTADO')
                        edita_medicion(this.item_selecto.id_receta, 'estado', 'ATENDIDO')
                        this.genera_garantia('GARANTIA')
                    }
                }
                if (items.modo == 'PARAMETRO') {
                    console.log('param')
                }
            }
        },
        async selecciona_reclamos() {
            this.dial_garantias = false
            await this.Busca_pedido()
        }
    }

}
</script>
