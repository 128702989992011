<template>
  <div class="mb-6 mt-2 pa-3">
    <v-row no-gutters class="mt-n1 mb-1">
      <v-col cols="6" class="pa-1" md="6" sm="4" xs="6">
        <v-card @click.prevent="nuevo_cliente()">
          <v-container>
            <v-img class="mx-auto" height="30" width="30" src="/clientes.png"></v-img>
            <h5 block class="text-center">Nuevo Cliente</h5>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-1" md="6" sm="4" xs="6" v-if="false">
        <v-card disabled>
          <v-container>
            <v-img class="mx-auto" height="30" width="30" src="/message.png"></v-img>
            <h5 block class="text-center">Enviar Publicidad</h5>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-text-field class="mb-n3" outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
    <v-data-table dense :headers="headers" :items="listafiltrada" mobile-breakpoint="1">
      <template v-slot:item="{ item }">
        <tr>
          <td width="50">{{ item.documento }}</td>
          <td>{{ item.nombre }}</td>
          <td>{{ item.telefono }}</td>
          <td width="150">
            <v-row no-gutters>
              <v-col cols="12">
                <v-icon color="green" @click="edita_cliente(item)">mdi-lead-pencil</v-icon>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogo" max-width="600px">
      <div>
        <v-system-bar window dark>
          <v-icon @click="dialogo = false">mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-icon color="red" large @click="eliminar()">mdi-delete</v-icon>
          <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
        </v-system-bar>
      </div>
      {{ calcula_fecha }}
      <v-card class="mx-auto pa-3" color="white">
        <v-row class="mt-n4">
          <v-col cols="6">
            <v-text-field outlined type="number" dense v-model="documento" label="N° Doc" append-icon="mdi-magnify"
              @click:append="buscar_bd()" @keyup.enter="buscar_bd()"></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field outlined type="number" dense v-model="telefono" label="Telefono"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n9">
          <v-col cols="12">
            <v-textarea outlined dense v-model="nombre" auto-grow filled label="Nombre" rows="1"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-n9">
          <v-col cols="6">
            <v-text-field outlined dense v-model="departamento" label="DEPARTAMENTO"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field outlined dense v-model="provincia" label="PROVINCIA"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-n9">
          <v-col cols="6">
            <v-text-field outlined dense v-model="distrito" label="DISTRITO"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field outlined dense v-model="ubigeo" label="UBIGEO"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n7" dense>
          <v-col cols="12">
            <v-textarea outlined dense v-model="direccion" auto-grow filled label="Direccion" rows="1"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-n5" dense>
          <v-col cols="12">
            <v-text-field type="email" outlined dense v-model="correo" label="CORREO"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n4' : 'mt-n5 text-center'">
          <v-col cols="12" md="6" xs="12">
            <v-text-field type="date" class="redondeado" outlined dense v-model="date1"
              label="Nacimento"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-n4' : 'text-center'">
            <v-text-field outlined dense v-model="edad" label="Edad"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col cols="6">
            <v-text-field outlined dense v-model="tienda" label="Tienda" readonly></v-text-field>
          </v-col>
          <v-col cols="6">
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoElimina" max-width="460px">
      <div>
        <v-system-bar window dark>
          <v-icon @click="dialogoElimina = !dialogoElimina">mdi-close</v-icon>
        </v-system-bar>
      </div>
      <v-card>
        <v-card-text>
          <h3> DESEA ELIMINAR PERMANENTEMENTE EL CLIENTE?</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="eliminar()">si</v-btn>
          <v-btn color="error" @click="dialogoElimina = !dialogoElimina">no</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {
  nuevoCliente,
  buscaCliente,
  eliminaCliente
} from '../../db'

import axios from "axios"
import store from '@/store/index'
import moment from 'moment'
export default {

  data: () => ({
    dialogoElimina: false,
    dialogo: false,
    buscar: '',
    headers: [{
      text: 'DOCUMENTO',
      value: 'documento',
    },
    {
      text: 'Nombre',
      value: 'nombre',
    },
    {
      text: 'Telefono',
      value: 'telefono',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    },
    ],
    date1: moment(String(new Date)).format('YYYY-MM-DD'),
    arraydocumento: ['DNI', 'RUC', 'CE'],
    departamento: 'LA LIBERTAD',
    provincia: 'TRUJILLO',
    distrito: 'TRUJILLO',
    direccion: '',
    ubigeo: '130101',
    nombre: '',
    tipodoc: 'RUC',
    documento: '',
    telefono: '',
    edad: '',
    correo: '',
    tienda: store.state.permisos.tienda,
    item_selecto: []
  }),

  computed: {
    listafiltrada() {
      this.desserts = store.state.clientes
      var filtro = this.desserts
      return filtro.filter((item) => (item.documento + item.nombre + item.direccion)
        .toLowerCase().includes(this.buscar.toLowerCase()))
    },
    calcula_fecha() {
      var nacimiento = moment(this.date1);
      var hoy = moment();
      var anios = hoy.diff(nacimiento, "years");
      this.edad = anios
    }
  },

  methods: {
    eliminar() {
      store.commit("dialogoprogress", 1);
      eliminaCliente(this.item_selecto.id)
      this.item_selecto = ''
      this.dialogo = false
      store.commit("dialogoprogress", 1);
    },
    nuevo_cliente() {
      this.documento = ''
      this.tipodoc = 'RUC'
      this.nombre = ''
      this.correo = ''
      this.departamento = 'LA LIBERTAD'
      this.provincia = 'TRUJILLO'
      this.distrito = 'TRUJILLO'
      this.ubigeo = '130101'
      this.direccion = ''
      this.telefono = ''
      this.fecha = moment(String(new Date)).format('YYYY-MM-DD')
      this.edad = 1
      this.tienda = store.state.permisos.tienda
      this.dialogo = true
    },
    async save() {
      store.commit("dialogoprogress", 1);
      var fecha = moment(String(this.date1)) / 1000
      var array = {
        activo: true,
        id: this.documento,
        tipodoc: this.tipodoc,
        documento: this.documento,
        nombre: this.nombre,
        correo: this.correo,
        departamento: this.departamento,
        provincia: this.provincia,
        distrito: this.distrito,
        ubigeo: this.ubigeo,
        direccion: this.direccion,
        telefono: this.telefono,
        nacimiento: fecha,
        edad: this.edad,
        tienda: this.tienda
      }
      await nuevoCliente(array.id, array)
      this.dialogo = false
      store.commit("dialogoprogress", 1);
    },
    edita_cliente(data) {
      this.item_selecto = data
      this.documento = data.id
      this.tipodoc = data.tipodoc
      this.documento = data.documento
      this.nombre = data.nombre
      this.correo = data.correo
      this.departamento = data.departamento
      this.provincia = data.provincia
      this.distrito = data.distrito
      this.ubigeo = data.ubigeo
      this.direccion = data.direccion
      this.telefono = data.telefono
      this.edad = data.edad
      this.tienda = data.tienda
      this.date1 = moment.unix(data.nacimiento).format('YYYY-MM-DD')
      this.dialogo = true
    },
    buscar_bd() {
      buscaCliente(this.documento.trim())
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            var data = snapshot.val()
            this.documento = data.id,
              this.tipodoc = data.tipodoc
            this.documento = data.documento
            this.nombre = data.nombre
            this.correo = data.correo
            this.departamento = data.departamento
            this.provincia = data.provincia
            this.distrito = data.distrito
            this.ubigeo = data.ubigeo
            this.direccion = data.direccion
            this.telefono = data.telefono
            this.edad = data.edad
            this.tienda = data.tienda
            this.date1 = moment.unix(data.nacimiento).format('YYYY-MM-DD')
          } else {
            this.BuscarDocumento()
          }
        })
    },
    BuscarDocumento() {
      if (this.documento != '' &&
        this.documento.length == 8 || this.documento.length == 11) {
        if (Number(this.documento.length) == 8) {
          this.tipodoc = "DNI"
        }
        if (Number(this.documento.length) == 11) {
          this.tipodoc = "RUC"
        }

        store.commit("dialogoprogress", 1)
        var self = this,
          token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
        axios
          .get('https://apiperu.dev/api/' + this.tipodoc.toLowerCase() + '/' + this.documento, {
            headers: {
              Content_Type: 'application/json',
              authorization: ' Bearer ' + token
            }
          })
          .then(response => (this.info = response.data,
            store.commit("dialogoprogress",),
            self.llenardatos(response.data.data)
          ))
          .catch(error => {
            store.commit("dialogoprogress", 1),
              self.snack('Documento Invalido')
          })
      } else {
        this.snack("Ingrese Documento")
      }
    },
    llenardatos(data) {
      this.nombre = data.nombre_o_razon_social
      if (this.tipodoc == 'DNI') {
        this.nombre = data.nombre_completo
      } else {
        this.direccion = data.direccion
        this.departamento = data.departamento
        this.provincia = data.provincia
        this.distrito = data.distrito
        this.ubigeo = data.ubigeo_sunat
      }

    },
  },
}
</script>
