<template>
    <div class="mb-6 pa-4">
        <v-card>
            <v-row>
                <v-col cols="2">
                    <v-select :items="array_tiendas" label="Tienda" dense outlined v-model="tienda"></v-select>
                </v-col>
                <v-col cols="3">
                    <v-select :items="array_estados" label="ESTADO" dense outlined v-model="estados"></v-select>
                </v-col>
                <v-col cols="3">
                    <v-text-field class="mx-1" outlined dense v-model="buscar" label="BUSCAR"
                        append-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-text-field class="mx-1" outlined dense type="date" v-model="date" label="Inicio"></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-text-field class="mx-1" outlined dense type="date" v-model="date2" label="Fin"></v-text-field>
                </v-col>
            </v-row>

            <v-simple-table fixed-header height='70vh' dense>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id">
                            <td :class="item.estado == 'PENDIENTE' ? 'id_card_rect' : ''">
                                <v-alert class="mt-2 mb-2" border="left" colored-border :color="item.color" elevation="2">
                                    <v-row>
                                        <v-col cols="9">
                                            <div class="text-h10" style="font-size:90%;">
                                                <strong> {{ item.id }}</strong>
                                                <strong :class="item.color + '--text'"> {{ item.estado }}</strong>
                                                <span style="font-size:90%;" v-if="item.estado == 'ANULADO'"><strong> --
                                                        MOTIVO :</strong> {{
                                                            item.motivo_anula }}</span>
                                                <span style="font-size:90%;"
                                                    v-if="item.estado == 'ACEPTADO' && Boolean(item.cod_pedido)"><strong> --
                                                        CODIGO :</strong> {{
                                                            item.cod_pedido }}</span>
                                                <span style="font-size:90%;"
                                                    v-if="item.estado == 'PENDIENTE' && Boolean(item.cod_pedido)"><strong>
                                                        -- OBS :</strong> {{
                                                            item.cod_pedido }}</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" class="pa-1 mb-4">
                                            <v-system-bar window color="white" class="pa-1">
                                                <v-spacer></v-spacer>
                                                <v-icon large v-if="item.p_confirmado" color="blue"
                                                    @click="imprime(item)">mdi-check-circle</v-icon>
                                                <v-spacer></v-spacer>
                                                <v-btn v-if="Boolean(item.id_compra)" class="ma-2" color="info" x-small
                                                    @click="abre_compra(item)">
                                                    COMPRA
                                                    <v-icon dark right> mdi-eye </v-icon>
                                                </v-btn>
                                                <v-icon large color="green" @click="imprime(item)">mdi-printer-eye</v-icon>
                                                <v-icon large color="error"
                                                    @click="edita_cabecera(item)">mdi-pencil</v-icon>
                                            </v-system-bar>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="mt-n4 my-1 info" style="opacity: 0.22"></v-divider>
                                    <v-row class="mt-n2">
                                        <v-col cols="3">
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>DOC.VENTA :</strong> {{ item.doc_venta }}
                                            </div>
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>F.VENTA :</strong> {{ conviertefecha(item.fecha) }}
                                            </div>
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>F.ENTREGA :</strong> ({{ item.producto.dias_entrega }}) - {{
                                                    conviertefecha2(item.f_entrega) }}
                                            </div>
                                            <div v-if="!item.garantia_reclamos" class="text-h12"
                                                style="font-size:80%; font-style: italic;">
                                                <strong>COSTO :</strong> S/.{{ item.total }}
                                            </div>
                                            <div v-if="!item.garantia_reclamos" class="text-h12"
                                                style="font-size:80%; font-style: italic;">
                                                <strong>A CUENTA :</strong> S/.{{ item.a_cuenta }}
                                                <v-icon color="green" @click="abre_a_cuenta(item)"
                                                    x-small>mdi-lead-pencil</v-icon>
                                            </div>
                                            <div v-if="!item.garantia_reclamos" class="text-h12"
                                                style="font-size:80%; font-style: italic;">
                                                <strong>TOTAL :</strong> S/.{{ item.total }}
                                            </div>
                                        </v-col>
                                        <v-col cols="6">
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>Producto :</strong> {{ item.producto.nombre }} - IR:{{
                                                    obten_indice(item) }}
                                                <v-icon color="green" @click="edita_producto(item)"
                                                    x-small>mdi-lead-pencil</v-icon>
                                            </div>
                                            <div v-if="item.nombre_ant != undefined" class="text-h12 red--text"
                                                style="font-size:80%; font-style: italic;">
                                                <strong>Prod.Ant. :</strong> {{ item.nombre_ant }} - IR:{{
                                                    obten_indice(item) }}

                                            </div>
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>Detalle :</strong>
                                                <strong class="red--text" v-if="item.producto.od"> OD </strong>
                                                <strong class="red--text" v-if="item.producto.oi"> - OI</strong>
                                            </div>
                                            <div>
                                                <v-row class="mt-n2">
                                                    <v-col cols="5">
                                                        <v-card @click.prevent="abre_receta(item)">
                                                            <v-container>
                                                                <v-img class="mx-auto" height="15" width="15"
                                                                    src="/receta.png"></v-img>
                                                                <h6 block class="text-center">RECETA</h6>
                                                            </v-container>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="5">
                                                        <v-card @click.prevent="abre_parametros(item)">
                                                            <v-container>
                                                                <v-img class="mx-auto" height="15" width="15"
                                                                    src="/parametros.png"></v-img>
                                                                <h6 block class="text-center">PARAMETROS</h6>
                                                            </v-container>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div v-if="item.p_confirmado" class="text-h12 mt-2"
                                                style="font-size:80%; font-style: italic;">
                                                <strong class="red--text">OBS : </strong>{{ item.obs_llegada }}

                                            </div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>TIENDA :</strong> {{ item.tienda }}
                                            </div>
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>VENDED :</strong> {{ item.vendedor }}
                                            </div>
                                            <div class="text-h12" style="font-size:80%; font-style: italic;">
                                                <strong>COMEN :</strong> {{ item.comentario }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-alert>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>
        <v-dialog v-model="dial_edita" max-width="500">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_edita = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row dense class="mb-2" style="font-size:80%; font-style: italic;">
                    <v-col cols="6" xs="6" sm="6">
                        <v-card @click.prevent="anula_pedido()" :disabled="item_selecto.p_confirmado">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/anular.png"></v-img>
                                <h5 block class="text-center pa-1">ANULA PEDIDO</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" xs="6" sm="6">
                        <v-card @click.prevent="confirma_pedido()" :disabled="item_selecto.p_confirmado">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/chek.gif"></v-img>
                                <h5 block class="text-center pa-1">CONFIRMA LLEGADA</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" xs="6" sm="6">
                        <v-card @click.prevent="re_ordena_pedido()" :disabled="Boolean(item_selecto.p_confirmado)">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/reenvia.png"></v-img>
                                <h5 block class="text-center pa-1">Re-Ordenar</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" xs="6" sm="6" v-if="es_convencional">

                        <v-card @click.prevent="guarda_codigo_pedido()"
                            :disabled="item_selecto.cod_pedido != undefined && item_selecto.cod_pedido != 'DESCARGADO SIN CODIGO DE CONFIRMACION'">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/contrato.png"></v-img>
                                <h5 block class="text-center pa-1">INGRESA CODIGO</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_a_cuenta" max-width="450">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dial_a_cuenta = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="guarda_acuenta()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mx-auto text-center">
                    <v-col cols="6">
                        <h4>SALDO DISPONIBLE : S/.{{ array_a_cuenta.saldo }}</h4>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense outlined type="number" v-model="monto_a_cuenta"
                            label="MONTO A USAR"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_detalle" max-width="850px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_detalle = !dial_detalle">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row dense>
                    <v-col cols="12">
                        <h4>Asiento : {{ detalle_compra.id }} - </h4>
                        <h4>{{ detalle_compra.num_doc }} - {{ detalle_compra.nom_proveedor }}</h4>
                        <h4>{{ detalle_compra.sreferencia }}-{{ detalle_compra.creferencia }} / {{
                            conviertefecha2(detalle_compra.fecha_emision) }}</h4>
                    </v-col>
                </v-row>
                <v-simple-table dark fixed-header max-width="75vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Cantidad.
                                </th>
                                <th class="text-left">
                                    Precio
                                </th>
                                <th class="text-left">
                                    Total
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in arrayConsolidar" :key="item.id">
                                <td>{{ item.nombre }} - {{ item.id_pedido }}</td>
                                <td>{{ item.cantidad }}</td>
                                <td>S/.{{ item.costo_nuevo }}</td>
                                <td>S/.{{ (item.costo_nuevo * item.cantidad) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>

        </v-dialog>
        <lista_productos v-if="dial_productos" :dial="dial_productos" @array="agrega_productos($event)"
            @cierra="dial_productos = false" :edicion="false" />
        <receta v-if="dial_receta" :data="data_receta" @cierra="dial_receta = false" />
        <parametros v-if="dial_parametros" @cierra="dial_parametros = false" :cliente="cliente_parame"
            :productos="producto_param" @guarda="guarda_parametro($event)" :parametro="parametro_medido" :editar="true" />
    </div>
</template>

<script>
import {
    allpedidos,
    busca_medicion,
    busca_receta_externa,
    busca_parametros,
    edita_pedido,
    busca_pendiente,
    edita_pendiente,
    edita_pendiente_bd,
    edita_cabecera_parametro,
    consulta_Movimientos,
    edita_pendientes_parametro
} from '../../db'
import {
    pdfGenera
} from '../../pdf_pedidos'
import store from '@/store/index'
import moment from 'moment'
import receta from '@/components/dialogos/dialog_receta'
import lista_productos from '@/components/almacen/lista_productos'
import parametros from '@/components/dialogos/dialog_parametros'

export default {
    components: {
        receta,
        lista_productos,
        parametros,
    },
    data: () => ({
        es_convencional: false,
        dial_detalle: false,
        dial_receta: false,
        dial_edita: false,
        dial_productos: false,
        dial_parametros: false,
        dial_a_cuenta: false,
        cliente_parame: [],
        producto_param: [],
        parametro_medido: [],
        data_receta: [],
        desserts: [],
        date: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        buscar: '',
        array_tiendas: ['TODAS'],
        tienda: 'TODAS',
        item_selecto: [],
        array_a_cuenta: [],
        monto_a_cuenta: '',
        array_estados: ['TODOS', 'PENDIENTE', 'ANULADO', 'ACEPTADO'],
        estados: 'PENDIENTE',
        arrayConsolidar: [],
        detalle_compra: []
    }),
    created() {
        this.date = moment(String(new Date)).subtract(2, 'd').format('YYYY-MM-DD')
        this.array_tiendas = store.state.tiendas
        this.array_tiendas.unshift('TODAS')
        var tiendas = store.state.permisos.tiendas
        for (var i = 0; i < tiendas.length; i++) {
            this.array_tiendas[this.array_tiendas.length] = tiendas[i]
        }
    },
    computed: {
        listafiltrada() {
            if (this.date != '') {
                if (this.date2 != '') {
                    allpedidos().orderByChild('fecha').startAt(moment(String(this.date)) / 1000)
                        .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
                }
            }

            var tt = this.desserts
            if (this.estados == 'TODOS') {
                var array = tt
            } else {
                var array = tt.filter(item => item.estado == this.estados)
            }
            if (this.tienda == 'TODAS') {
                return array.filter((item) => (item.id + item.doc_venta + item.cliente_dni + item.cliente_nombre + item.producto.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase())).reverse()
            } else {
                array = array.filter((item) => (item.tienda).toLowerCase().includes(this.tienda.toLowerCase()))
                return array.filter((item) => (item.id + item.doc_venta + item.cliente_dni + item.cliente_nombre + item.producto.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase())).reverse()

            }
        }
    },
    mounted() {
        allpedidos().orderByChild('fecha').startAt(moment(String(this.date)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    destroyed() {
        allpedidos().off("value");
    },
    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                //console.log(data)
                if (data.estado == 'PENDIENTE') {
                    data.color = 'orange'
                }
                if (data.estado == 'ANULADO') {
                    data.color = 'red'
                }
                if (data.estado == 'ACEPTADO') {
                    data.color = 'green'
                }

                data.f_entrega = moment(this.conviertefecha(data.fecha), 'DD/MM/YYYY hh:mm A').add(data.producto.dias_entrega, 'd') / 1000
                array.push(data);
            });
            this.desserts = array;
        },
        obten_indice(id) {
            //console.log(id.producto.id)
            var i = store.state.productos.find(item => item.id == id.producto.id)
            if (i == undefined) {
                return
            }
            return i.indice

        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },
        conviertefecha2(date) {
            return moment.unix(date).format('DD/MM/YYYY')
        },
        edita_producto(item) {
            this.item_selecto = item
            if (confirm("Esta Seguro de modificar este producto?")) {
                this.dial_productos = true
            }
        },
        edita_cabecera(item) {
            console.log(item)
            this.es_convencional = false
            if (item.producto.tipo_focal.includes('DIGITAL')) {
                console.log("as")
                this.es_convencional = true
            }
            this.item_selecto = item
            this.dial_edita = true
        },
        async abre_receta(item) {
            console.log(item.id_receta)
            store.commit("dialogoprogress", 1)
            if (item.modo_receta == 'INTERNA') {
                var snap = await busca_medicion(item.id_receta).once("value")
                var array = snap.val()
            } else {
                var snap = await busca_receta_externa(item.cliente_dni, item.id_receta).once("value")
                var array = snap.val()
                array.especialista = 'RECETA EXTERNA'
            }
            store.commit("dialogoprogress", 1)
            this.data_receta = array
            this.dial_receta = true

        },
        async abre_parametros(item) {

            this.item_selecto = item
            if (Boolean(item.producto.id_parametro)) {
                store.commit("dialogoprogress", 1)
                var snap = await busca_parametros(item.cliente_dni, item.producto.id_parametro).once("value")
                if (snap.exists()) {

                    this.parametro_medido = snap.val()
                    this.cliente_parame = {
                        nom_cliente: snap.val().dni,
                        dni_cliente: item.cliente_dni,
                        especialista: snap.val().tecnico
                    }
                    this.producto_param = item.producto
                    store.commit("dialogoprogress", 1)
                    this.dial_parametros = true
                } else {
                    alert('ERROR INTERNO')
                }
            } else {
                if (item.modo_receta == 'INTERNA') {
                    var snap = await busca_medicion(item.id_receta).once("value")
                    var opto = snap.val().especialista
                } else {
                    var snap = await busca_receta_externa(item.cliente_dni, item.id_receta).once("value")
                    var opto = 'RECETA EXTERNA'
                }
                this.parametro_medido = false
                this.producto_param = item.producto
                this.cliente_parame = {
                    nom_cliente: item.cliente_nombre,
                    dni_cliente: item.cliente_dni,
                    especialista: opto
                }
                this.dial_parametros = true
            }

        },
        async guarda_parametro(item) {
            edita_pendientes_parametro(this.item_selecto.id, 'estado', 'COMPLETO')
            edita_cabecera_parametro(this.item_selecto.cliente_dni, this.item_selecto.id, 'id_parametro', item.id)
            edita_cabecera_parametro(this.item_selecto.cliente_dni, this.item_selecto.id, 'estado', 'COMPLETO')
            await edita_pedido(this.item_selecto.id, 'producto/id_parametro', item.id)
            this.dial_parametros = false
        },
        agrega_productos(value) {
            console.log(value)
            if (this.item_selecto.nombre_ant == undefined) {
                edita_pedido(this.item_selecto.id, 'nombre_ant', this.item_selecto.producto.nombre)
                this.dial_productos = false
            }
            edita_pedido(this.item_selecto.id, 'producto/id', value.id)
            edita_pedido(this.item_selecto.id, 'producto/id', value.id)
            edita_pedido(this.item_selecto.id, 'producto/nombre', value.marca + ' ' + value.descripcion)




        },
        anula_pedido() {
            if (confirm("Esta Seguro de Anular este Pedido?")) {
                var motivo = prompt("INGRESE EL MOTIVO");
                edita_pedido(this.item_selecto.id, 'estado', 'ANULADO')
                edita_pedido(this.item_selecto.id, 'motivo_anula', motivo.toUpperCase())
                edita_pendiente_bd(this.item_selecto.bd, this.item_selecto.id_a_cuenta + '/producto/' + this.item_selecto.producto.uuid, 'cod_pedido', '')
                this.dial_edita = false
            }
        },

        async imprime(item) {
            item.indice_r = this.obten_indice(item)
            var array_tienda = store.state.array_tiendas.find(i => i.nom_tienda == item.tienda)
            console.log(array_tienda)
            if (item.modo_receta == 'INTERNA') {
                var receta = await busca_medicion(item.id_receta).once("value")
            } else {
                var receta = await busca_receta_externa(item.cliente_dni, item.id_receta).once("value")
            }
            if (item.producto.tipo_focal.includes('DIGITAL')) {
                if (Boolean(item.producto.id_parametro)) {
                    var param = await busca_parametros(item.cliente_dni, item.producto.id_parametro).once("value")
                    if (item.estado == 'PENDIENTE') {
                        edita_pedido(item.id, 'cod_pedido', 'DESCARGADO SIN CODIGO DE CONFIRMACION')
                    }
                    console.log(param.val())
                    pdfGenera(item, receta.val(), param.val(), array_tienda)
                } else {
                    alert('FALTA AGREGAR PARAMETROS')
                }
            } else {
                if (item.estado == 'PENDIENTE') {
                    //   edita_pedido(item.id, 'estado', 'ACEPTADO')
                    edita_pedido(item.id, 'cod_pedido', '**')
                }
                var param = {
                    naso_pu_oi: receta.val().dnp_oi,
                    naso_pu_od: receta.val().dnp_od
                }
                pdfGenera(item, receta.val(), param, array_tienda)
            }
        },
        async abre_a_cuenta(item) {
            store.commit("dialogoprogress", 1)
            this.item_selecto = item
            this.monto_a_cuenta = ''
            var snap = await busca_pendiente(item.bd, item.id_a_cuenta).once('value')
            if (snap.exists()) {
                this.array_a_cuenta = snap.val()
                this.dial_a_cuenta = true
            }
            store.commit("dialogoprogress", 1)
        },
        async guarda_acuenta() {

            if (parseFloat(this.array_a_cuenta.saldo) < parseFloat(this.monto_a_cuenta)) {
                alert('MONTO SUPERA EL SALDO DISPONIBLE')
            } else {
                store.commit("dialogoprogress", 1)
                var suma = parseFloat(this.array_a_cuenta.saldo) - parseFloat(this.monto_a_cuenta)
                var sum_a_cuenta = parseFloat(this.item_selecto.a_cuenta) + parseFloat(this.monto_a_cuenta)
                await edita_pedido(this.item_selecto.id, 'a_cuenta', sum_a_cuenta)
                await edita_pendiente(this.array_a_cuenta.id, 'saldo', suma)
                this.dial_a_cuenta = false
                store.commit("dialogoprogress", 1)
            }
        },
        async abre_compra(item) {
            console.log(item.id_compra)
            this.arrayConsolidar = []
            var snap = await consulta_Movimientos(item.id_compra).once('value')
            if (snap.exists()) {
                console.log(snap.val())
                this.detalle_compra = snap.val()
                this.arrayConsolidar = snap.val().data
                this.dial_detalle = true
            } else {
                this.arrayConsolidar = []
                this.dial_detalle = true
            }
        },
        async confirma_pedido() {

            if (this.item_selecto.producto.tipo_focal.includes('DIGITAL')) {
                if (this.item_selecto.cod_pedido == undefined) {
                    alert('NO HA INGRESADO CODIGO DE SOLICITUD')
                    return
                }
                if (this.item_selecto.estado == 'PENDIENTE') {
                    var text = "QUIEN ES LA PERSONA QUE RECIBE ESTE PRODUCTO?"
                } else {
                    var text = "Esta Seguro de la llegada de este producto?"
                }
            } else {
                if (this.item_selecto.estado == 'PENDIENTE') {
                    var text = "INGRESE NOMBRE DEL LABORATORIO"
                } else {
                    var text = "Esta Seguro de la llegada de este producto?"
                }
            }
            console.log(this.item_selecto)
            if (confirm('Esta Seguro de la llegada de este producto?')) {
                var motivo = prompt(text);
                edita_pedido(this.item_selecto.id, 'obs_llegada', motivo.toUpperCase())
                edita_pedido(this.item_selecto.id, 'p_confirmado', true)
                edita_pedido(this.item_selecto.id, 'estado', 'ACEPTADO')
                await edita_pendiente_bd(this.item_selecto.bd, this.item_selecto.id_a_cuenta, 'producto/' + this.item_selecto.producto.uuid + '/ubicacion', 'TALLER')
                this.dial_edita = false
            }
        },
        async guarda_codigo_pedido() {
            var cod_pedido = prompt("INGRESE CODIGO DE ORDEN");
            if (cod_pedido != '') {
                edita_pedido(this.item_selecto.id, 'cod_pedido', cod_pedido)
                edita_pedido(this.item_selecto.id, 'estado', 'ACEPTADO')
                await edita_pendiente_bd(this.item_selecto.bd, this.item_selecto.id_a_cuenta + '/producto/' + this.item_selecto.producto.uuid, 'cod_pedido', cod_pedido)
            }
            this.dial_edita = false
        },
        re_ordena_pedido() {

            if (confirm("Esta Seguro de Volver a Pedir este producto?")) {
                edita_pedido(this.item_selecto.id, 'estado', 'PENDIENTE')
                edita_pedido(this.item_selecto.id, 'cod_pedido', '')
                edita_pendiente_bd(this.item_selecto.bd, this.item_selecto.id_a_cuenta + '/producto/' + this.item_selecto.producto.uuid, 'cod_pedido', '')
                this.dial_edita = false
            }
        },
    }
}
</script>

<style scoped>
@keyframes anim {
    0% {
        background-color: rgb(255, 255, 255);

    }

    50% {
        background-color: rgb(255, 196, 196);
    }

    100% {
        background-color: rgb(255, 255, 255);
    }
}

.id_card_rect {
    animation-name: anim;
    animation-duration: 2s;
    animation-iteration-count: infinite;

}
</style>
