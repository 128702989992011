<template>
    <div class="mb-6 pa-4">
        <v-card>
            <div class="d-flex align-content-start flex-wrap">
                <v-row class="mt-n2 pa-1">
                    <v-col cols="4">
                        <v-card elevation="12" class="mt-n1" color="white" @click="dial_pendientes = true">
                            <v-container class="mb-n12 ml-1">
                                <v-badge :content="num_pendientes" :value="num_pendientes" color="green" overlap
                                    class="ml-10">
                                </v-badge>
                            </v-container>
                            <v-container>
                                <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/time.png"></v-img>
                                <p class="text-center mb-n2 mt-2">PENDIENTES</p>
                            </v-container>

                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="date" outlined dense v-model="date" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined dense autofocus label="Busca Cliente" auto-select-first v-model="busca_p"
                            :items="arra_clientes" append-icon="mdi-magnify" @click:append="busca()"
                            @keyup.enter="busca()"></v-autocomplete>
                    </v-col>
                </v-row>
            </div>

            <v-simple-table fixed-header height='70vh' dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Fecha
                            </th>
                            <th class="text-left">
                                Doc Venta
                            </th>
                            <th class="text-left">
                                Cliente
                            </th>
                            <th class="text-left">
                                Producto
                            </th>
                            <th class="text-left">
                                Estado
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id">
                            <td style="font-size:75%;">{{ conviertefecha(item.fecha) }}</td>
                            <td style="font-size:75%;">{{ item.doc_venta }}</td>
                            <td style="font-size:75%;">{{ item.cliente_nombre }}</td>
                            <td style="font-size:75%;">{{ item.nom_producto }}</td>
                            <td>
                                <v-icon color="#FFB200" v-if="item.estado == 'PENDIENTE'">mdi-circle</v-icon>
                                <v-icon color="#46FF00" v-if="item.estado == 'COMPLETO'">mdi-circle</v-icon>
                                <strong class="red--text" v-if="item.editado">Edit.</strong>
                            </td>
                            <td style="font-size:75%;">
                                <v-icon class="mr-3" color="success" @click="edita_cabecera(item, false)">mdi-eye</v-icon>
                                <v-icon v-if="item.estado == 'PENDIENTE'" color="error"
                                    @click="edita_cabecera(item, true)">mdi-pencil</v-icon>
                                <v-icon v-if="item.estado == 'COMPLETO'" color="error"
                                    @click="abre_dial_edita(item)">mdi-pencil</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>
        <v-dialog v-model="dial_garantia" max-width="500">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_garantia = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>GENERA GARANTIA</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <p><strong>ESTA SEGURO DE EDITAR ESTE PARAMETRO?</strong></p>
                <v-select style="font-size:75%;" :items="array_optometra" label="Optometra Responsable de Edicion" dense
                    outlined v-model="especialista"></v-select>
                <v-row class="mt-n4" dense>
                    <v-col cols="12">
                        <v-btn block class="text-center" color="success" @click="abre_parametro()"
                            :disabled="especialista == ''">CONTINUAR</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_pendientes" max-width="600">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_pendientes = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>SELECCIONA CLIENTE</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-simple-table class="elevation-1 mt-2" fixed-header height="50vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Paciente
                                </th>
                                <th class="text-left">
                                    Tienda
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_pendientes" :key="item.id" @click="selecciona_pendiente(item)">
                                <td>{{ item.cliente_nombre }}</td>
                                <td>{{ item.tienda }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <parametros v-if="dial_parametros" @cierra="dial_parametros = false" :cliente="cliente_parame"
            :productos="producto_param" @guarda="guarda_parametro($event)" :parametro="parametro_medido"
            :editar="edita_param" />
    </div>
</template>

<script>
import {
    all_cabecera_parametros,
    busca_parametros,
    edita_pedido,
    edita_cabecera_parametro,
    busca_tabla,
    nueva_garantia_p,
    all_garantias_p,
    all_pendientes_parametros,
    edita_pendientes_parametro
} from '../../db'
import store from '@/store/index'
import moment from 'moment'
import parametros from '@/components/dialogos/dialog_parametros'
export default {

    components: {
        parametros
    },
    data: () => ({
        producto_param: [],
        cliente_parame: [],
        parametro_medido: [],
        dial_parametros: false,
        date: moment(String(new Date)).format('YYYY-MM-DD'),
        documento: '',
        desserts: [],
        dialog: false,
        arrayConsolidar: [],
        buscar: '',
        rechazados: 0,
        error: '',
        seleccionado: '',
        num_doc: '',
        tipo_doc: 'T',
        arra_clientes: [],
        busca_p: '',
        num_cliente: '',
        item_selecto: [],
        dial_garantia: false,
        array_optometra: [],
        especialista: '',
        edita_param: false,
        array_pendientes: [],
        num_pendientes: 0,
        dial_pendientes: false,
        selecto: []
    }),
    created() {
        this.carga_clientes()
    },
    computed: {
        listafiltrada() {
            return this.desserts.reverse()
        }
    },
    mounted() {
        all_pendientes_parametros().orderByChild('estado').equalTo('PENDIENTE').on("value", this.onDataChange);
    },
    destroyed() {
        all_pendientes_parametros().off("value", this.onDataChange);
    },
    methods: {
        selecciona_pendiente(data) {
            this.selecto = data
            this.busca_p = data.cliente_dni + ' / ' + data.cliente_nombre
            this.dial_pendientes = false
            this.busca()
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                console.log(data)
                if (data.estado == 'PENDIENTE' && data.tienda == store.state.permisos.tienda) {

                    array.push(data);
                }

            });
            this.num_pendientes = array.length
            this.array_pendientes = array.reverse();
        },
        async guarda_parametro(item) {
            if (item.tecnico_ant != '') {
                edita_cabecera_parametro(this.item_selecto.cliente_dni, this.item_selecto.id, 'editado', true)
                this.genera_garantia(item)
            }
            edita_cabecera_parametro(this.item_selecto.cliente_dni, this.item_selecto.id, 'id_parametro', item.id)
            edita_cabecera_parametro(this.item_selecto.cliente_dni, this.item_selecto.id, 'estado', 'COMPLETO')
            edita_pendientes_parametro(this.item_selecto.id, 'estado', 'COMPLETO')
            edita_pedido(this.item_selecto.id_pedido, 'producto/id_parametro', item.id)
            this.dial_parametros = false
            this.busca()

        },
        async genera_garantia(array) {
            console.log(array)
            var it = []
            var snap = await all_garantias_p().orderByChild('dni_cliente').equalTo(this.item_selecto.cliente_dni).once("value")
            if (snap.exists()) {
                snap.forEach((item) => {
                    var data = item.val()
                    if (data.estado == 'PENDIENTE') {
                        it = data
                    }

                })
            }
            if (it == '') {
                var fecha = moment().unix()
                var arr = {
                    fecha: fecha,
                    nom_cliente: this.item_selecto.cliente_nombre,
                    dni_cliente: this.item_selecto.cliente_dni,
                    producto: this.item_selecto.producto,
                    id_receta: array.id,
                    modo: 'PARAMETRO',
                    estado: 'PENDIENTE',
                    espe: array.tecnico,
                    espe_ant: array.tecnico_ant
                }
                await nueva_garantia_p(arr)
            }
        },
        async edita_cabecera(item, edt) {
            console.log(item)
            this.item_selecto = item
            store.commit("dialogoprogress", 1)
            if (item.id_parametro != '') {
                var snap = await busca_parametros(item.cliente_dni, item.id_parametro).once('value')
                if (snap.exists()) {
                    this.cliente_parame = {
                        nom_cliente: item.cliente_nombre,
                        dni_cliente: item.cliente_dni,
                        especialista: snap.val().tecnico
                    }
                    this.parametro_medido = snap.val()
                    this.producto_param = item.producto
                    this.dial_parametros = true
                } else {
                    alert('ERROR INTERNO')
                }
            } else {
                console.log(item)
                this.cliente_parame = {
                    nom_cliente: item.cliente_nombre,
                    dni_cliente: item.cliente_dni,
                    especialista: item.optometra
                }
                this.parametro_medido = ''
                this.producto_param = item.producto
                this.dial_parametros = true
            }
            this.edita_param = edt
            store.commit("dialogoprogress", 1)
        },
        carga_clientes() {
            var array = store.state.clientes
            for (var i = 0; i < array.length; i++) {
                this.arra_clientes[this.arra_clientes.length] = array[i].documento + ' / ' + array[i].nombre
            }
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YY')
        },
        async busca() {
            var array = []
            this.desserts = []
            this.num_cliente = this.busca_p.split('/')[0].trim()
            if (this.num_cliente != '') {
                var snapshot = await all_cabecera_parametros(this.num_cliente)
                    .once("value")
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        var data = item.val()
                        if (data.doc_venta == this.selecto.doc_venta) {
                            this.edita_cabeceras(data)
                        }
                        array.push(data)
                    })
                    this.desserts = array
                } else {
                    store.commit('dialogosnackbar', 'SIN INFORMACION')
                }

            }
        },
        edita_cabeceras(val) {
            console.log(val.estado)
            if (val.estado == 'COMPLETO') {
                edita_pendientes_parametro(this.selecto.id, 'estado', 'COMPLETO')
            }
        },
        async abre_dial_edita(value) {
            console.log(value)
            this.item_selecto = value
            var snapshot = await busca_tabla('tecnicos').once("value")
            snapshot.forEach((item) => {
                let data = item.val();
                this.array_optometra[this.array_optometra.length] = data.nombre
            });
            this.dial_garantia = true
        },
        async abre_parametro() {
            var item = this.item_selecto
            var snap = await busca_parametros(item.cliente_dni, item.id_parametro).once('value')
            if (snap.exists()) {
                this.cliente_parame = {
                    nom_cliente: item.cliente_nombre,
                    dni_cliente: item.cliente_dni,
                    especialista: snap.val().tecnico
                }
                this.parametro_medido = snap.val()
                this.parametro_medido.tecnico_ant = this.parametro_medido.tecnico
                this.parametro_medido.tecnico = this.especialista
                this.producto_param = item.producto
                this.dial_garantia = false
                this.edita_param = true
                this.dial_parametros = true
            }
        }
    }
}
</script>

<style></style>
