<template>
  <v-dialog v-model="dial" max-width="650px">
    <div>
      <v-system-bar window dark>
        <v-icon large color="red" @click="$store.commit('abre_qr', false)">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-icon :color="color_camara()" large @click="camara = !camara">mdi-camera-plus</v-icon>
      </v-system-bar>
    </div>
    <v-card class="pa-3">
      <v-text-field autofocus v-model="buscar" append-icon="mdi-magnify" label="Buscar" single-line hide-details dense
        @keyup.enter="busca_montura(buscar)"></v-text-field>
      <StreamBarcodeReader v-if="camara" @decode="(a, b, c) => onDecode(a, b, c)" @loaded="() => onLoaded()">
      </StreamBarcodeReader>
    </v-card>
    <v-card class="pa-3" v-if="lista_modelos">
      <v-simple-table class="elevation-1" fixed-header height="60vh">
        <template v-slot:default>
          <thead>

            <tr>
              <th class="text-left">
                id
              </th>
              <th class="text-left">
                Marca
              </th>
              <th class="text-left">
                Modelo
              </th>
              <th class="text-left">
                Color
              </th>
              <th class="text-left">
                Precio
              </th>
              <th class="text-left">
                Ubicacion
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in listafiltrada" :key="item.id">
              <td style="font-size:75%;">{{ item.id }}</td>
              <td style="font-size:75%;">{{ item.marca }}</td>
              <td style="font-size:75%;">{{ item.modelo }}</td>
              <td style="font-size:75%;">{{ item.color }}</td>
              <td style="font-size:75%;">S/.{{ item.precio }}</td>
              <td style="font-size:75%;">{{ item.ubicacion }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="dialo_cantidad" max-width="350px">
      <v-card class="pa-3">
        <h4 class="mb-2">CODIGO : {{ item_selecto.id }}</h4>
        <v-text-field type="number" outlined dense v-model="precio" :label="precio_min" @focus="$event.target.select()"
          @keyup.enter="agregaCatalogo()"></v-text-field>
        <v-row class="mt-2 mb-6 text-center" dense v-if="false">
          <v-col cols="4">
            <v-icon @click="suma()" color="green">mdi-plus</v-icon>
          </v-col>
          <v-col cols="4">
            <v-text-field type="number" outlined dense v-model="cantidad" label="CANTIDAD"
              @keyup.enter="agregaCatalogo()"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-icon @click="resta()" color="red">mdi-minus</v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-n2 mb-6 text-center" dense>
          <v-col cols="6">
            <v-card @click="abre_modelos()">
              <v-container>
                <v-img class="mx-auto" height="40" width="40" src="/mont.png"></v-img>
                <h6 block class="text-center">MODELOS</h6>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card @click="agregaCatalogo()">
              <v-container>
                <v-img class="mx-auto" height="40" width="40" src="/cash.png"></v-img>
                <h6 block class="text-center">VENDER</h6>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import {
  StreamBarcodeReader
} from "vue-barcode-reader";
import {
  busca_medicion,
  allMonturas
} from '../db'
import store from '@/store/index'

export default {
  name: "lector",
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      camara: false,
      text: "",
      id: null,
      dial: false,
      dialo_cantidad: false,
      item_selecto: [],
      precio: '',
      precio_min: '',
      cantidad: 1,
      buscar: '',
      qr_dial: true,
      lista_modelos: false,
      filtra_modelo: ''
    };
  },
  created() {
    this.dial = true
  },
  props: {
    msg: String,
  },
  computed: {
    listafiltrada() {
      var array = this.filtra_modelo
      return array
    }
  },
  methods: {
    color_camara() {
      if (this.camara) {
        return 'green'
      } else {
        return 'red'
      }
    },
    async abre_modelos() {
      store.commit("dialogoprogress", 1)
      this.buscar = ''
      this.filtra_modelo = []
      var snap = await allMonturas().orderByChild('modelo').equalTo(this.item_selecto.modelo).once('value')
      this.filtra_modelo = snap.val()
      this.qr_dial = false
      this.dialo_cantidad = false
      this.lista_modelos = true
      store.commit("dialogoprogress", 1)
    },
    onDecode(a, b, c) {
      console.log(a, b, c);
      this.text = a;
      this.busca_montura(a)

      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
          this.text = "";
        }
      }, 5000);
    },

    onLoaded() {
      console.log("load");
    },
    async busca_montura(value) {
      console.log(value)
      if (value[0] == '.') {
        var data = value.substr(1)
        var snap = await busca_medicion(data).once('value')
        var item = snap.val()
        item.modo = 'INTERNA'
        store.commit('array_paciente', item)
        this.qr_dial = false
        store.commit('abre_qr', false)
      } else {
        var item = store.state.monturas.find(item => item.id == value)
        if (item == undefined) {
          alert('PRODUCTO NO EXISTE')
          this.buscar = ''
          return
        }
        this.precio_min = "PRECIO MIN : S/." + item.precio_min
        this.precio = item.precio
        this.cantidad = 1
        this.item_selecto = item
        this.qr_dial = false
        this.dialo_cantidad = true
      }
    },
    agregaCatalogo() {
      var item = this.item_selecto
      console.log(item)
      var array = {
        id: item.id,
        marca: item.marca,
        modelo: item.modelo,
        cantidad: this.cantidad,
        precio: this.precio,
        operacion: item.operacion,
        tipoproducto: item.tipoproducto,
      }
      if (parseFloat(this.precio) < parseFloat(item.precio_min)) {
        store.commit('dialogosnackbar', 'Revise Precio')
      } else {
        this.$emit('resultado', array)
        this.dialo_cantidad = false
      }
    },

  },
};
</script>

<style scoped></style>
