<template>
    <div class="pa-6">
        <v-row dense class="text-center">
            <v-col cols="6">
                <h4>Usuario: {{ permisosususario.nombre }}</h4>
                <h4>Correo: {{ permisosususario.correo }}</h4>
                <h4>Tiendas: {{ permisosususario.tienda }}</h4>
            </v-col>
            <v-row dense class="text-center">

                <v-col cols="3">
                    <v-btn elevation="10" rounded color="green" @click="grabaPermiso()">
                        <v-icon color="white" class="mx-auto text--center" medium>mdi-content-save</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn elevation="10" rounded color="red" @click="regresar()">
                        <v-icon color="white" class="mx-auto text--center" medium>mdi-keyboard-return</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn v-if="false" class="mt-1" color="red" small dark @click="abre_tiendas()">
                        TIENDAS
                        <v-icon dark right>
                            mdi-storefront
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-row>

        <v-card>
            <v-row dense>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.master" label="Es Master"></v-checkbox>
                </v-col>
                <v-col cols="6">

                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.moduloempresa" label="Configuracion"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulosunat" label="Sunat"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulokardex" label="Kardex"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.optometria" label="Optometria"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.pedidos" label="Pedidos"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.moduloproductos" label="Almacen"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.modulocaja" label="Ventas"></v-checkbox>
                </v-col>
                <v-col cols="6">

                </v-col>
            </v-row>
        </v-card>

        <v-card class="pa-4 mt-3">
            <v-card-title class="mt-n6">
                Clientes
            </v-card-title>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.moduloclientes" label="Clientes"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.clientes_edita" label="Editar Clientes"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.clientes_elimina" label="Eliminar Clientes"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.clientes_crea" label="Crear Clientes"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="pa-4 mt-3">
            <v-card-title class="mt-n6">
                Reportes
            </v-card-title>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.moduloreportes" label="Reportes"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense class="mt-n6">
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.reportes_comprobantes" label="Comprobantes"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="permisosususario.reportes_ranking" label="Ranking"></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="dial_tiendas" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_tiendas = !dial_tiendas" color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon @click="crear_tienda()" color="green">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="mx-auto pa-3" color="white">
                <v-simple-table class="elevation-1 mt-2" fixed-header height="60vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    TIENDA
                                </th>
                                <th class="text-left">
                                    DIRECCION
                                </th>
                                <th class="text-left">
                                    ACCION
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in lista_tiendas" :key="item.id_tienda">
                                <td>{{ item.nom_tienda }}</td>
                                <td>{{ item.direccion }}</td>
                                <td>
                                    <v-icon @click="selecciona_tienda(item)" color="red">mdi-cursor-default-outline</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import {
    buscarUsuarios,
    nuevoUsuario,
    all_tienda
} from '../db'
import store from '@/store/index'
export default {

    data() {
        return {
            token: '',
            nombre: '',
            correo: '',
            pass: '',
            valid: true,
            desserts: [],
            dialogPermisos: false,
            permisosususario: [],
            dial_tiendas: false,
            lista_tiendas: [],
            array_tiendas: []
        }
    },
    created() {
        this.inicio()
    },
    methods: {
        inicio() {

            buscarUsuarios(this.$route.params.id).once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    this.permisosususario = snapshot.val()
                }
            })
        },

        cargaPermisos(items) {
            this.dialogPermisos = true
            this.token = items.token
            this.correo = items.correo
            this.nombre = items.nombre
            this.pass = items.pass
            var funciones = {
                token: items.token,
                nombre: items.nombre,
                correo: items.correo,
                bd: items.bd,
                pass: items.pass,
                moduloempresa: items.moduloempresa,
                modulokardex: items.modulokardex,
                modulosunat: items.modulosunat,
                moduloproductos: items.moduloproductos,
                moduloclientes: items.moduloclientes,
                clientes_edita: items.clientes_edita,
                clientes_elimina: items.clientes_elimina,
                clientes_crea: items.clientes_crea,
                modulocaja: items.modulocaja,
                modulocomprobantes: items.modulocomprobantes,
                moduloreportes: items.moduloreportes,
                reportes_comprobantes: items.reportes_comprobantes,
                reportes_ranking: items.reportes_ranking,
                optometria: items.optometria,
                pedidos: items.pedidos,
                master: items.master,
                tienda: items.tienda
            }

            this.permisosususario = funciones
        },

        grabaPermiso() {
            var items = this.permisosususario
            var corre = ''
            var passsw = ''
            if (items.correo != '') {
                corre = items.correo
                passsw = items.pass
            } else {
                corre = 'Pendiente'
                passsw = ''
            }
            var array = {
                token: items.token,
                nombre: items.nombre,
                correo: items.correo,
                bd: items.bd,
                pass: items.pass,
                moduloempresa: items.moduloempresa,
                modulokardex: items.modulokardex,
                modulosunat: items.modulosunat,
                moduloproductos: items.moduloproductos,
                moduloclientes: items.moduloclientes,
                clientes_edita: items.clientes_edita,
                clientes_elimina: items.clientes_elimina,
                clientes_crea: items.clientes_crea,
                modulocaja: items.modulocaja,
                modulocomprobantes: items.modulocomprobantes,
                moduloreportes: items.moduloreportes,
                reportes_comprobantes: items.reportes_comprobantes,
                reportes_ranking: items.reportes_ranking,
                optometria: items.optometria,
                pedidos: items.pedidos,
                master: items.master,
                tienda: items.tienda
            }

            nuevoUsuario(items.token, array).then(() => {
                store.commit('dialogosnackbar', "GUARDADO CON EXITO")
            })
        },
        regresar() {
            this.$router.push({
                path: "/gestionUsuarios/"
            })
        },
        abre_tiendas() {
            let array = [];
            all_tienda().once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    let data = item.val();
                    array.push(data);
                });
                this.dial_tiendas = true
                this.lista_tiendas = array
            })

        },
        selecciona_tienda(data) {
            console.log(data)
            this.array_tiendas[this.array_tiendas.length] = data.nom_tienda
            this.permisosususario.tiendas = this.array_tiendas
            console.log(this.array_tiendas)
            this.dial_tiendas = false
        }
    }

}
</script>
