<template>
    <v-dialog v-model="dial" max-width="650" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h5 style="font-size:85%" v-if="filtro_producto != ''">{{ filtro_producto }} / {{ filtro_marca }} / {{
        filtro_material }} / {{
        filtro_tratamiento }}</h5>
                <v-spacer></v-spacer>
                <v-icon v-if="!productos" large color="red" @click="regresa()">mdi-keyboard-return</v-icon>
            </v-system-bar>
        </div>
        <v-card>
            <v-simple-table fixed-header class="pa-1" v-if="productos">
                <template v-slot:default>
                    <thead>
                    </thead>
                    <tbody>

                        <v-row class="mt-1 mb-1 mx-auto text-center" style="text-align:center" dense>
                            <v-col v-for="item in $store.state.tipo_focal" :key="item.id" cols="6"
                                class="pa-1 text-center mx-auto" md="6" sm="6" xs="6">
                                <v-card height="80" @click.prevent="ir_marcas(item)">
                                    <v-card-text class="red--text" style="font-size:95%">
                                        <span class="black--text">{{ item }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </tbody>
                </template>
            </v-simple-table>
            <v-simple-table fixed-header class="pa-1" v-if="marc">
                <template v-slot:default>
                    <thead>
                    </thead>
                    <tbody>

                        <v-row class="mt-1 mb-4 mx-auto text-center" style="text-align:center" dense>
                            <v-col v-for="item in marcas" :key="item.id" cols="6" class="pa-1 text-center mx-auto"
                                md="6" sm="6" xs="6">
                                <v-card height="80" @click.prevent="ir_material(item.nombre)">
                                    <v-card-text style="font-size:95%">
                                        <span class="black--text">{{ item.nombre }}</span>
                                        <p v-if="filtra_indices(item.nombre) != 0"
                                            style="font-size:75%;font-weight: bold;">
                                            {{
        filtra_indices(item.nombre) }}</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </tbody>
                </template>
            </v-simple-table>
            <v-simple-table fixed-header class="pa-1" v-if="material">
                <template v-slot:default>
                    <thead>
                    </thead>
                    <tbody>

                        <v-row class="mt-1 mb-4 mx-auto text-center" style="text-align:center" dense>
                            <v-col v-for="item in array_material" :key="item.id" cols="6"
                                class="pa-1 text-center mx-auto" md="6" sm="6" xs="6">
                                <v-card height="80" @click.prevent="ir_detalle(item.tipo_material)">
                                    <v-card-text class="red--text" style="font-size:95%">
                                        <span class="black--text">{{ item.tipo_material }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </tbody>
                </template>
            </v-simple-table>
            <v-simple-table fixed-header class="pa-1" v-if="false">
                <template v-slot:default>
                    <thead>
                    </thead>
                    <tbody>

                        <v-row class="mt-1 mb-4 mx-auto text-center" style="text-align:center" dense>
                            <v-col v-for="item in array_tratamientos" :key="item.id" cols="6"
                                class="pa-1 text-center mx-auto" md="6" sm="6" xs="6">
                                <v-card height="80" @click.prevent="ir_detalle(item.tratamiento)">
                                    <v-card-text class="red--text" style="font-size:95%">
                                        <span class="black--text">{{ item.tratamiento }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </tbody>
                </template>
            </v-simple-table>

            <v-simple-table fixed-header height="70vh" dense v-if="detalle">
                <template v-slot:default>

                    <thead>
                        <tr>
                            <th class="text-left">
                                Indice
                            </th>
                            <th class="text-left">
                                Producto
                            </th>
                            <th class="text-left">
                                Color
                            </th>
                            <th class="text-left" v-if="edicion">
                                Precio
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="item in   array_detalle  " :key="item.id" @click="selecciona(item)">
                            <td>{{ item.indice }}</td>
                            <td>
                                <font :color="item.c_palabra"> {{ item.descripcion }} </font>
                            </td>
                            <td>
                                <v-icon @click="consultaApisunat(item)" :color="item.c_icono">mdi-circle</v-icon>
                            </td>
                            <td v-if="edicion">S/.{{ item.precio }}</td>
                        </tr>
                    </tbody>
                    <v-card-text>

                    </v-card-text>

                </template>
            </v-simple-table>
            <v-simple-table fixed-header class="pa-1" v-if="tratamiento_lentes">
                <template v-slot:default>
                    <thead>
                    </thead>
                    <tbody>

                        <v-row class="mt-1 mb-4 mx-auto text-center" style="text-align:center" dense>
                            <v-col v-for="  item   in   array_trat_lent  " :key="item.id" cols="6"
                                class="pa-1 text-center mx-auto" md="6" sm="6" xs="6">
                                <v-card height="80" @click.prevent="ir_detalle_lentes(item)">
                                    <v-card-text class="red--text" style="font-size:95%">
                                        <span class="black--text">{{ item }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </tbody>
                </template>
            </v-simple-table>
            <v-simple-table fixed-header height="60vh" dense v-if="detalles_lentes">
                <template v-slot:default>

                    <thead>
                        <tr>
                            <th class="text-left">
                                Producto
                            </th>
                            <th class="text-left">
                                Contenido
                            </th>
                            <th class="text-left" v-if="edicion">
                                Precio
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="  item   in   array_detalle_lentes  " :key="item.id" @click="selecciona(item)">
                            <td>{{ item.descripcion }}</td>
                            <td>{{ item.contenido }}</td>
                            <td v-if="edicion">S/.{{ item.precio }}</td>
                        </tr>
                    </tbody>
                    <v-card-text>

                    </v-card-text>

                </template>
            </v-simple-table>

        </v-card>
        <v-dialog v-model="dialo_cantidad" max-width="450px">
            <v-card class="pa-3 mx-auto text-center">
                <h5 class="mb-6">{{ item_selecto.marca }} / {{ item_selecto.tratamiento }} / {{ item_selecto.descripcion
                    }}
                </h5>
                <v-row class="mt-n2 mb-n1 mx-auto text-center" style="text-align:center" dense v-if="edicion">
                    <v-col cols="6">
                        <v-checkbox v-model="od" label="O.D."></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox v-model="oi" label="O.I."></v-checkbox>
                    </v-col>
                </v-row>
                {{ calcula_precio }}
                <v-text-field v-if="edicion" type="number" autofocus outlined dense v-model="precio_edita"
                    label="PRECIO" @focus="$event.target.select()"></v-text-field>
                <v-text-field v-if="edicion" type="number" outlined dense v-model="dias_entrega" label="Dias de entrega"
                    @focus="$event.target.select()"></v-text-field>

                <v-row class="mt-n2 mb-6 text-center" dense v-if="edicion">
                    <v-col cols="6">
                        <v-card @click="agregaCatalogo(1)" :disabled="permite_obsequio()">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/gift.png"></v-img>
                                <h6 block class="text-center">OBSEQUIO</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click="agregaCatalogo(2)">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/cash.png"></v-img>
                                <h6 block class="text-center">COBRAR</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card @click="agregaCatalogo(2)" v-if="!edicion">
                    <v-container>
                        <v-img class="mx-auto" height="40" width="40" src="/doctor.png"></v-img>
                        <h6 block class="text-center">Seleccionar</h6>
                    </v-container>
                </v-card>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import store from '@/store/index'

import {
    busca_tabla
} from '../../db'
export default {
    props: {
        dial: '',
        edicion: '',
        serie: ''
    },
    data() {
        return {
            productos: true,
            marc: false,
            material: false,
            tratamientos: false,
            detalle: false,
            dialo_cantidad: false,
            filtro_producto: '',
            filtro_marca: '',
            filtro_material: '',
            filtro_tratamiento: '',
            array_marcas: [],
            item_selecto: '',
            precio: '',
            od: true,
            oi: true,
            alto_indice: false,
            dias_entrega: 7,
            tratamiento_lentes: '',
            array_trat_lent: ['ESFERA', 'ASTIGMATISMO'],
            detalles_lentes: false,
            array_productos: [],
            array_lentes: [],
            filt_trat_lente: '',
            precio_edita: ''

        }
    },
    created() {
        this.inicio()
    },
    computed: {

        marcas() {
            return this.array_marcas.filter((item) => (item.tipo_focal == this.filtro_producto))
        },
        array_material() {
            var hash = {};

            var array = this.array_productos.filter((item) => (item.marca.trim() == this.filtro_marca))
            var array2 = this.array_productos.filter((item) => (item.marca.trim() == this.filtro_marca))
            array = array.filter(function (current) {
                var exists = !hash[current.tipo_material];
                hash[current.tipo_material] = true;
                return exists;
            });
            array2 = array2.filter(function (current) {
                var exists = !hash[current.indice];
                hash[current.indice] = true;
                return exists;
            });
            var fin = array2.filter((item) => (item.indice == 1.67 || item.indice == 1.74))
            if (fin != '') {
                array.push({
                    tipo_material: 'RESINA ALTO INDICE'
                })
            }
            return array
        },
        array_tratamientos() {
            var hash = {};
            var array = this.array_productos.filter((item) => (item.marca.trim() == this.filtro_marca && item.tipo_material == this.filtro_material))
            array = array.filter(function (current) {
                var exists = !hash[current.tratamiento];
                hash[current.tratamiento] = true;
                return exists;
            });
            return array
        },
        array_detalle() {
            //console.log(this.filtro_producto, this.filtro_marca, this.filtro_tratamiento, this.serie)
            if (this.alto_indice) {
                var arr = this.array_productos.filter((item) => (item.tipo_focal == this.filtro_producto &&
                    item.marca.trim() == this.filtro_marca && item.indice == 1.67 || item.tipo_focal == this.filtro_producto &&
                    item.marca.trim() == this.filtro_marca && item.indice == 1.74))
            } else {
                var arr = this.array_productos.filter((item) => (item.tipo_focal == this.filtro_producto &&
                    item.marca.trim() == this.filtro_marca && item.tipo_material == this.filtro_tratamiento))
            }
            if (this.filtro_producto == 'MONOFOCAL STANDAR') {
                return arr.filter((item) => (item.descripcion)
                    .toLowerCase().includes(this.serie.toLowerCase()))
            } else {
                return arr
            }

        },
        array_detalle_lentes() {
            return this.array_lentes.filter((item) => (item.tratamiento == this.filt_trat_lente))
        },
        calcula_precio() {

            var precio = this.item_selecto.precio
            if (this.od && !this.oi) {
                precio = (precio / 2).toFixed(2)
            }
            if (!this.od && this.oi) {
                precio = (precio / 2).toFixed(2)
            }
            this.precio_edita = precio
            return ''
        }
    },
    methods: {
        inicio() {
            this.array_productos = store.state.productos
            this.array_lentes = store.state.lentes_c
            busca_tabla('productos').once("value")
                .then((snapshot) => {
                    let array = [];
                    snapshot.forEach((item) => {
                        let data = item.val();
                        if (data.activo) {
                            array.push(data);
                        }

                    });
                    this.array_marcas = array;
                })
        },
        ir_detalle_lentes(item) {
            this.filt_trat_lente = item
            this.tratamiento_lentes = false
            this.detalles_lentes = true
        },
        ir_marcas(data) {
            this.productos = false
            if (data == 'LENTES DE CONTACTO') {
                this.tratamiento_lentes = true
            } else {
                if (data == 'POLARIZADO ESPEJADO') {
                    this.filtro_producto = data
                    this.filtro_marca = 'RESINA ESPEJADA POLARIZADO'
                    this.ir_detalle('RESINA')
                }
                else {
                    this.filtro_producto = data
                    this.marc = true
                }
            }

        },
        ir_material(data) {
            console.log(data)
            this.alto_indice = false
            this.filtro_marca = data
            this.marc = false
            this.material = true
        },
        ir_tratamientos(data) {
            if (data == 'RESINA ALTO INDICE') {
                this.alto_indice = true
                this.es_alto_indice()
            } else {
                this.alto_indice = false
                this.filtro_material = data
                this.material = false
                this.tratamientos = true
            }
        },
        es_alto_indice() {

            this.material = false
            this.tratamientos = true
            this.ir_detalle('RESINA')
        },
        ir_detalle(data) {
            if (data == 'RESINA ALTO INDICE') {
                this.alto_indice = true
                this.es_alto_indice()
            } else {
                this.filtro_tratamiento = data
                this.tratamientos = false
                this.detalle = true
                this.material = false // es sin el detalle
            }
        },
        selecciona(item) {
            if (item.tipo_focal != undefined) {
                if (item.tipo_focal.includes('DIGITAL')) {
                    this.dias_entrega = 7
                } else {
                    this.dias_entrega = 1
                }
            } else {
                this.dias_entrega = 1
            }

            this.oi = true
            this.od = true
            this.precio = item.precio
            this.precio_edita = item.precio
            this.cantidad = 1
            this.item_selecto = item
            this.detalles_lentes = false
            this.dialo_cantidad = true
        },
        agregaCatalogo(modo) {
            var item = this.item_selecto
            if (parseFloat(item.costo) > parseFloat(this.precio_edita)) {
                alert('PRECIO POR DEBAJO DEL LIMITE PERMITIDO')
                return
            }
            var precio = this.precio_edita
            var operacion = item.operacion
            if (precio == 0) {
                modo = 1
            }
            if (modo == 1) {
                operacion = 'GRATUITA'
                precio = 0
            }
            var array = {
                id: item.id,
                marca: item.marca,
                descripcion: item.descripcion,
                cantidad: 1,
                precio: precio,
                operacion: operacion,
                tipoproducto: item.tipoproducto,
                tipo_focal: item.tipo_focal,
                od: this.od,
                oi: this.oi,
                dias_entrega: this.dias_entrega,
                costo: item.costo
            }
            this.$emit('array', array)
            this.dialo_cantidad = false
            this.detalle = false
            this.productos = true
            this.filtro_producto = ''
        },
        cierra() {
            this.$emit('cierra', false)
        },
        regresa() {
            if (this.marc) {
                this.marc = false
                this.productos = true
                this.filtro_producto = ''

            }
            if (this.material) {
                this.material = false
                this.marc = true
                this.filtro_marca = ''

            }
            if (this.tratamientos) {
                this.tratamientos = false
                this.material = true
                this.filtro_material = ''
            }

            if (this.tratamiento_lentes) {
                this.tratamiento_lentes = false
                this.productos = true
            }
            if (this.detalles_lentes) {
                this.detalles_lentes = false
                this.productos = true
            }
            if (this.detalle) {
                this.detalle = false
                this.alto_indice = false
                this.filtro_tratamiento = ''
                if (this.filtro_marca == 'RESINA ESPEJADA POLARIZADO') {
                    this.filtro_producto = ''
                    this.filtro_marca = ''
                    this.productos = true
                } else {
                    this.material = true
                }



            }

        },
        filtra_indices(data) {
            var array = this.array_productos.filter((item) => (item.marca == data))
            var rr = []
            for (var i = 0; i < array.length; i++) {
                rr[rr.length] = array[i].indice
            }
            const unicos = rr.filter((valor, indice) => {
                return rr.indexOf(valor) === indice;
            });
            var fin = unicos.filter((item) => (item == 1.67 || item == 1.74))
            if (fin != '') {
                var t = 'A.I'
            }
            return t
        },
        permite_obsequio() {
            var tratamiento = this.item_selecto.tratamiento
            var permite = false
            if (this.filtro_marca == 'RESINA CONVENCIONAL') {
                if (tratamiento == 'U.V') {
                    permite = false
                }
                if (tratamiento == 'ANTIRREFLEJO') {
                    permite = false
                }
                if (tratamiento == 'FILTRO DE LUZ AZUL') {
                    permite = false
                }
            }
            return permite
        }

    },

}
</script>
